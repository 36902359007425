import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import loadIcons from '@sp/core/helper/icons/load';
import i18n from '@sp/core/i18n';

import '@sp/editor/app/lightShared';

import Landing from './screens/landing';
import EmailConfirm from './screens/landing/Email/Confirm';

import '@sp/styles/general/index.scss';

loadIcons('landing');

const getResellerInfo = () => {
  if (!window.BRANDING) return;

  return {
    loginType: 'branded',
    resellerName: window.BRANDING?.partnerName,
    brandFavicon: window.BRANDING?.favicon,
    brandIcon: window.BRANDING?.logoTaken,
    brandingTheme: window.BRANDING?.brandingTheme,
    loginUrl: window.BRANDING?.loginUrl,
    brandUrl: window.BRANDING?.brandUrl,
  };
};

const getComponent = (pages) => {
  const current = window.location.pathname;
  const { screen } = pages.find((page) => page.url.some((link) => link.includes(current)));

  return screen;
};

const buildScreen = (reseller) => {
  const pages = [
    {
      screen: Landing,
      url: ['/', '/login/', '/register/'],
    },
    {
      screen: EmailConfirm,
      url: ['/email-confirmed/'],
    },
  ];
  const Screen = getComponent(pages);
  const container = document.querySelector('#app');
  const root = createRoot(container);

  const i18nScreen = (
    <I18nextProvider i18n={i18n}>
      <Screen reseller={reseller} />
    </I18nextProvider>
  );

  root.render(i18nScreen);
};

const initApp = () => {
  const reseller = getResellerInfo();

  return buildScreen(reseller);
};

initApp();
