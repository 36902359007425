const isNil = (value) => value === undefined || value === null;

const merge = (obj, ...objs) => {
  const clearObjs = objs.filter(Boolean);
  let baseObj = obj;

  if (clearObjs.length === 0) return obj;
  if (clearObjs.length === 1 && isNil(obj)) return clearObjs[0];

  if (isNil(obj)) baseObj = {};

  clearObjs.forEach((obj2) => {
    const stack = [{ base: baseObj, merged: obj2 }];

    while (stack.length > 0) {
      const { base, merged } = stack.pop();

      Object.keys(merged).forEach((index) => {
        const value = merged[index];

        if (base[index] === value) return;

        if (typeof value === 'object'
          && base[index]
          && typeof base[index] === 'object') {
          stack.push({ base: base[index], merged: merged[index] });

          return;
        }

        base[index] = merged[index];
      });
    }
  });

  return baseObj;
};

export default merge;
