/**
 * Prepare body request
 * @param {FormData|File|Object} body
 * @returns {FormData|string|File}
 */
const prepareBodyRequest = (body) => {
  if (body instanceof FormData) {
    return body;
  }

  if (body instanceof File) {
    return body;
  }

  return JSON.stringify(body);
};

export default prepareBodyRequest;
