const omitBy = (obj, fn) => {
  let newObj = obj;

  if (typeof newObj === 'object') {
    newObj = Array.isArray(newObj) ? [...newObj] : { ...newObj };
  }

  Object.keys(obj).forEach((key) => {
    if (fn(obj[key], key)) {
      delete newObj[key];
    }
  });

  return newObj;
};

export default omitBy;
