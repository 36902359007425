import isEqual from '@sp/core/helper/lodash/isEqual';
import omitBy from '@sp/core/helper/lodash/omitBy';
import mitt from 'mitt';

const { body } = document;

export const classNames = {
  cropMode: 'crop-mode',
  disableEditor: '_disable-editor',
  enableSelection: '_enable-selection',
  disableHighlight: '_disable-highlight',
  enableNav: '_enable-nav',
  disableNav: '_disable-nav',
  drop_imgIn: 'drop_img-in',
  grid: 'grid_active',
  gridButton: 'grid_button',
  gridDragging: 'grid_dragging',
  gridEmpty: 'section__drag-empty',
  gridShape: 'grid_shape',
  gridSlider: 'grid_slider',
  gridUser: 'grid_user',
  gridTimeline: 'grid_timeline',
  hideDrag: 'hide-drag',
  imageCrop: 'global-crop',
  imgOver: 'img-over',
  logoCrop: 'global-crop-logo',
  modal_open: 'modal_open',
  noOverflowY: 'no-overflow-y',
  noneEvent: 'none-event',
  noneEventResize: '_resizing',
  noneEvents: 'pointer-none',
  pageDisable: 'page_disable',
  pageToolbarOpened: 'page_toolbar_opened',
  resizeHorizontal: '_resizing-h',
  sidebarTab: 'sidebar-tab',
  tangentMove: 'line_moving',
  toolbarForm: '_toolbar2',
  toolbarOpened: '_toolbar2-opened',
  _hide_resize: '_hide-resize',
  editorCreate: '_editor-update',
  // TODO: rename '_store-delete' to more generic name
  editorDelete: '_store-delete',
  isEcwidPages: 'is_ecwid_pages',
  _minisite: '_minisite',
  embedLoad: '_embed-load',
  dragBlocks: '_drag-blocks',
  _empty_page: '_empty-page',
  hasScroll: '_has-scroll',
  dragPalette: 'color_dragged',
  disablePointer: '_disable-pointer',
  isBlogPage: 'blog-page',
  catalogIsLoading: 'catalog_is_loading',
  hideHelpBox: 'help-hidden',
  // Contrast ratio button colors
  primarySolidBackgroundLight: '_primary-light',
  secondarySolidBackgroundLight: '_secondary-light',
  primaryOutlineColorLight: '_primary_border-text-light',
  primaryOutlineBorderLight: '_primary_border-light',
  secondaryOutlineColorLight: '_secondary_border-text-light',
  secondaryOutlineBorderLight: '_secondary_border-light',
  primarySolidBackgroundDark: '_primary-dark',
  secondarySolidBackgroundDark: '_secondary-dark',
  primaryOutlineColorDark: '_primary_border-text-dark',
  primaryOutlineBorderDark: '_primary_border-dark',
  secondaryOutlineColorDark: '_secondary_border-text-dark',
  secondaryOutlineBorderDark: '_secondary_border-dark',
  // Contrast ratio theme colors
  primaryThemeDark: '_primary_theme-dark',
  primaryThemeLight: '_primary_theme-light',
  secondaryThemeDark: '_secondary_theme-dark',
  secondaryThemeLight: '_secondary_theme-light',
  textThemeDark: '_text_theme-dark',
  textThemeLight: '_text_theme-light',
  primaryBtnTooInputContrast: '_primary-btn_too-light',
  secondaryBtnTooInputContrast: '_secondary-btn_too-light',
  lightTextThemeDark: '_light-text_theme-dark',
  lightTextThemeLight: '_light-text_theme-light',
  textThemeInputContrast: '_theme_text_contrast',
  lightTextThemeInputContrast: '_theme_light-text_contrast',
  modalConfirmOpened: 'modal-confirm_opened',
  page404: '_page-404',
  withDoubleHeader: '_with-double-header',
  withMfeHeader: '_with-mfe-header',
  menuDragShow: '_menu-drag_show',
  wideLogo: '_wide-logo',
  hasQuickStart: '_has-quick-start',
  mobileSidebarOpened: 'e-menu-more-opened',
  toolbarIsOpen: '_toolbar-is-open',
  verticalResizeMobile: '_vertical-resize-mobile',
  pageLoader: '_page-ai-loader',
  isAIWriterOpen: '_ai-writer-is-open',
  featuresTestMode: '_features-test-mode',
  trialBannerOut: '_trial-banner-out',
};

let state = {};
const emitter = mitt();

/**
 * Function to add or remove classes on document body.
 * There is an ability to set dynamic classes:
 * bodyClass.set({sidebarTab: true}) -> adds 'sidebar-tab'
 * bodyClass.set({sidebarTab: 'xxx'}) -> adds 'sidebar-tab__xxx', removes 'sidebar-tab'
 * bodyClass.set({sidebarTab: '' || false}) -> removes 'sidebar-tab__xxx'
 * bodyClass.set({dynamicName: 'yyy'}) -> adds 'yyy'
 * bodyClass.set({dynamicName: false}) -> removes 'yyy'
 *
 * @param nextState - next classNames
 * @param {boolean} directClassInsert
 */
function setState(nextState, directClassInsert) {
  if (typeof classNames === 'function') nextState = nextState(state);

  const newState = { ...state, ...nextState };

  if (!isEqual(newState, state)) {
    const difference = omitBy(newState, (value, key) => state[key] === value);

    emitter.emit('beforeSet', difference);
    Object.keys(difference)
      .map((key) => {
        const value = difference[key];
        const className = directClassInsert ? key : classNames[key];
        const isClassDynamic = typeof value === 'string';

        // Check and remove previous dynamic class
        const prevState = state[key] || '';
        const isPrevClassDynamic = typeof prevState === 'string';
        const prevPrefix = isPrevClassDynamic && className
          ? `${className}__`
          : className || '';
        const prevClassName = isPrevClassDynamic
          ? prevPrefix + prevState
          : prevPrefix;

        if (prevClassName) body.classList.remove(prevClassName);

        if (isClassDynamic) {
          if (value) {
            const prefix = className ? `${className}__` : '';

            body.classList.add(prefix + value);
          }
        } else if (className) {
          if (value) {
            body.classList.add(className);
          } else {
            body.classList.remove(className);
          }
        }
      });
    state = { ...newState, ...difference };
    Object.keys(state)
      .forEach((el) => {
        emitter.emit(el, state[el]);
      });
  }
}

export default {
  set: setState,
  emitter,
  get: (name) => state[name],
  getState: () => state,
};
