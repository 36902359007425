import Cookies from 'js-cookie';

import cookie from '@sp/core/api/cookie';
import is from '@sp/core/helper/env/is';
import isObject from '@sp/core/helper/lodash/isObject';
import warn from '@sp/core/helper/warn';

const domain = `${is.local ? '' : '.'}${window.location.hostname}`;

export const updateToken = ({
  accessToken,
  rememberMe,
}) => {
  Cookies.set('accessToken', accessToken, {
    expires: rememberMe ? 30 : 1,
    domain: is.local ? undefined : domain,
  });

  // Success
  return accessToken;
};

export const logout = (logoutUrl) => {
  const accessToken = cookie.get('accessToken');

  if (domain) {
    cookie.remove('accessToken', { domain });
  }

  window.location.href = logoutUrl || `/logout${accessToken ? `?accessToken=${accessToken}` : ''}`;
};

export const redirectToLogin = (err, cb) => {
  warn(isObject(err), 'Error exist');
  const { status } = err.response || {};

  if (status === 400) {
    logout();
  } else if (typeof cb === 'function') {
    cb();
  }
};
