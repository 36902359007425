import isNil from '@sp/core/helper/lodash/isNil';

const getPrefixedName = ({
  name,
  type,
  value,
  prefix = 'function',
}) => `${prefix}_${name}_${type}${isNil(value) ? '' : `_${value}`}`;

export default getPrefixedName;
