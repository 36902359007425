import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import cn from 'classnames';
import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';

import Button from '@sp/component/button';
import Input from '@sp/component/Input';
import { postAuth } from '@sp/core/api/member';
import is from '@sp/core/helper/env/is';
import isEmpty from '@sp/core/helper/lodash/isEmpty';
import isNil from '@sp/core/helper/lodash/isNil';
import { updateToken } from '@sp/core/helper/redirect';
import IconEmail from '@sp/icon/LoginEmail';
import IconPassword from '@sp/icon/LoginPassword';

import { setBrandingThemeStyles } from '../../utils';

import { handleSubmitErrors, validateValues } from './validation';

const authorize = async (loginCredentials) => {
  const response = await postAuth(loginCredentials);
  const authData = response.data;
  // we should use sso url only in 2 cases:
  // 1. on real prod
  // 2. during whitelabel testing
  const shouldUseSsoUrl = !is.review && ((!is.dev && !is.stage) || !window.BRANDING);

  if (shouldUseSsoUrl && authData.ssoUrl) {
    window.location.href = authData.ssoUrl;

    return;
  }

  updateToken({
    ...authData,
    rememberMe: loginCredentials.rememberMe,
  });
  window.location.href = '/dashboard';
};

class Authorization extends Component {
  constructor(props) {
    super(props);

    const brandingTheme = props?.reseller?.brandingTheme;

    setBrandingThemeStyles(brandingTheme);

    this.passwordFieldRef = React.createRef();
    this.switcherRef = React.createRef();

    this.state = {
      loading: false,
      preventShowError: true,
      errorMessage: '',
      switchedType: 'password',
      isPasswordFocused: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleSubmitByKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleSubmitByKey);
  }

  handleSubmitByKey = (event) => {
    if (isHotkey('enter', event)) {
      this.setState({
        loading: true,
      });

      if (!isNil(this.formRef)) {
        this.formRef.current.handleSubmit();
      }
    }
  };

  handleSubmit = (loginCredentials) => {
    const {
      email,
      password,
    } = loginCredentials;
    const errors = validateValues({
      email,
      password,
    });

    if (!isEmpty(errors)) {
      this.setState({
        loading: false,
        preventShowError: false,
      });

      return Promise.resolve(errors);
    }

    return authorize(loginCredentials)
      .catch((error) => handleSubmitErrors(error)
        .then((er) => this.setState({
          loading: false,
          preventShowError: false,
          errorMessage: er.email,
        })));
  };

  handleInputFocus = (e) => {
    if (e.target.name === 'password') {
      this.setState({
        isPasswordFocused: true,
      });
    }
  };

  handleInputBlur = (e, onBlur) => {
    const { preventShowError } = this.state;

    if (!preventShowError) {
      this.setState({
        preventShowError: true,
        errorMessage: '',
      });
    }

    this.setState({
      isPasswordFocused: false,
    });

    onBlur();
  };

  togglePasswordTypeHandle = () => {
    const { switchedType } = this.state;

    this.setState({
      switchedType: switchedType === 'password' ? 'text' : 'password',
      isPasswordFocused: true,
    });

    this.focusPasswordField();
  };

  focusPasswordField = () => {
    const { current } = this.passwordFieldRef;

    if (isNil(current)) return;

    const inputNode = current?.childNodes[0];

    if (inputNode.tagName === 'INPUT' && inputNode.name === 'password') {
      inputNode.focus();
    }
  };

  render() {
    const {
      loading,
      preventShowError,
      errorMessage,
      switchedType,
      isPasswordFocused,
    } = this.state;
    const {
      reseller: {
        resellerName,
        brandIcon,
      },
    } = this.props;
    const loginFormTitle = `Login to ${resellerName || 'Site Editor'}`;

    return (
      <div className="auth__wrap">
        <Form
          onSubmit={this.handleSubmit}
          render={({
            handleSubmit,
            submitting,
          }) => {
            const buttonClasses = cn('auth__btn', {
              btn_spinner: !!loading,
            });

            return (
              <form
                className="auth__form"
                onSubmit={handleSubmit}
                ref={() => this.formRef.current = { handleSubmit }}
              >
                {brandIcon && (
                  <div className="auth__logo">
                    <img src={brandIcon} alt="" />
                  </div>
                )}
                <div className="auth__title">
                  {loginFormTitle}
                </div>
                <Field name="email">
                  {({
                    input,
                    meta,
                  }) => {
                    const { submitError } = meta;
                    const { onBlur } = input;

                    return (
                      <Input
                        {...input}
                        placeholder="Email"
                        error={submitError || errorMessage}
                        preventShowError={preventShowError}
                        onBlur={(e) => this.handleInputBlur(e, onBlur)}
                        onFocus={(e) => this.handleInputFocus(e)}
                      >
                        <div className="input__icon-holder">
                          <IconEmail className="icon_email" />
                        </div>
                      </Input>
                    );
                  }}
                </Field>
                <Field name="password">
                  {({
                    input,
                    meta,
                  }) => {
                    const { submitError } = meta;
                    const { onBlur, value } = input;

                    return (
                      <Input
                        {...input}
                        placeholder="Password"
                        type={switchedType}
                        error={submitError}
                        preventShowError={preventShowError}
                        onFocus={(e) => this.handleInputFocus(e)}
                        onBlur={(e) => this.handleInputBlur(e, onBlur)}
                        passwordRef={this.passwordFieldRef}
                      >
                        <div className="input__icon-holder">
                          <IconPassword className="icon_password" />
                        </div>
                        {(value) && (
                          <button
                            className={cn('btn input__password-switch', {
                              _focus: isPasswordFocused,
                            })}
                            type="button"
                            ref={this.switcherRef}
                            onClick={() => this.togglePasswordTypeHandle()}
                          >
                            {switchedType === 'password' ? 'SHOW' : 'HIDE'}
                          </button>
                        )}
                      </Input>
                    );
                  }}
                </Field>

                <Button
                  disabled={submitting}
                  onClick={() => this.setState({ loading: true })}
                  text="Log In"
                  type="submit"
                  className={buttonClasses}
                />
              </form>
            );
          }}
        />
        <footer className="auth__footer">
          Copyright &copy; 2024 Site Editor. All rights reserved.
          <span>
            <a className="auth__link" href="/legal/terms"> Terms of Service</a>
            {' '}
            |
            <a className="auth__link" href="/legal/privacy"> Privacy Policy</a>
            {' '}
            |
            <a className="auth__link" href="/legal/gdpr"> GDPR</a>
            .
          </span>
        </footer>
      </div>
    );
  }
}

Authorization.defaultProps = {
  reseller: {},
};
Authorization.propTypes = {
  reseller: PropTypes.shape({
    brandIcon: PropTypes.string,
    resellerName: PropTypes.string,
    brandingTheme: PropTypes.object,
  }),
};

export default Authorization;
