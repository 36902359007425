import React from 'react';
import classnames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import isString from '@sp/core/helper/lodash/isString';

import ButtonText from './ButtonText';

import './index.scss';

const Button = ({
  children,
  className,
  defaultClass = 'btn2',
  disabled,
  icon,
  innerRef,
  onClick,
  onMouseDown,
  onMouseUp,
  style,
  tabIndex = -1,
  text,
  textPosition = 'after',
  title,
  type = 'button',
  isTranslatable = true,
}) => {
  const classNames = classnames(defaultClass, className);

  let iconCloned;

  if (icon) {
    iconCloned = React.cloneElement(icon, {
      key: 'iconCloned',
    });
  }

  const textElement = text && (
    <ButtonText
      key="labelElement"
      text={isTranslatable ? i18next.t(text) : text}
    />
  );

  const translatedChildren = isString(children) && isTranslatable ? i18next.t(children) : children;
  const buttonChildren = textPosition === 'before'
    ? [textElement, iconCloned, translatedChildren]
    : [translatedChildren, iconCloned, textElement];

  return (
    <button
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={innerRef}
      style={style}
      title={title}
      // eslint-disable-next-line react/button-has-type
      type={type}
      tabIndex={tabIndex}
    >
      {buttonChildren}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  // Text for button
  text: PropTypes.string,
  // Place text before of after the passed children
  textPosition: PropTypes.oneOf(['before', 'after']),
  tabIndex: PropTypes.number,
  isTranslatable: PropTypes.bool,
  defaultClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array, PropTypes.string]),
  icon: PropTypes.shape({}),
  title: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
};

export default Button;
