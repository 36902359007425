/* eslint-disable unicorn/prefer-regexp-test */
/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';

import { FS_API_SERVICE, STATIC_SERVICE } from '@sp/core/api/api_urls';
import is from '@sp/core/helper/env/is';
import isEmpty from '@sp/core/helper/lodash/isEmpty';
import isObject from '@sp/core/helper/lodash/isObject';
import compilePath from '@sp/core/helper/urls/compilePath';
import isStatic from '@sp/core/helper/urls/isStatic';

const FULL_SRC_URL = /^\s*https?:\/{2}[^.]+\..+/gi;

export const pictureQueries = {
  '479px': 7,
  '767px': 5,
  '1024px': 3,
  '1366px': 2,
  '1920px': 1,
  '2560px': 0,
  default: 0,
};

// dashboard previews, etc ...
export const templateQueries = {
  '479px': 14,
  '767px': 13,
  '1024px': 12,
  '1366px': 11,
  '1920px': 10,
  '2560px': 9,
  default: 9,
};

// Base64 or Blob file
const isBaseOrBlob = (source) => /(^data\s*:+.*;+\s*base.*)|(^blob\s*:.*)/gi.test(source);

export const getImage = (src, fallback = 'default.png') => {
  if (src === undefined || src === null) {
    console.warn(`Invalid image src of type ${src}! Used fallback instead`);
    // eslint-disable-next-line no-param-reassign
    src = fallback;
  }

  // TODO hot fix for template preview
  if (isObject(src)) {
    // eslint-disable-next-line no-param-reassign
    src = src[Object.keys(src)[0]];
  }

  if (src.length === 0) {
    console.error('Image src should not be empty! Used fallback instead');
    // eslint-disable-next-line no-param-reassign
    src = fallback;
  }

  return src.includes('://')
    ? src
    : `${STATIC_SERVICE}/${src.replaceAll('\'', '')}`;
};

export const getImageUrl = (src) => {
  if (src === null || src === undefined) return '';

  if (typeof src === 'string') return src;

  if (isBaseOrBlob(src.name)) return src.name.toString();

  const {
    source,
    name,
    path,
  } = src;

  if (!is.builder) {
    const file = source === undefined ? 0 : source;

    if (!isEmpty(name) && name.match(FULL_SRC_URL)) {
      const pathToCompile = name.includes(':size')
        ? name
        : `${name}?size=:size`;

      return compilePath(pathToCompile, { size: file });
    }

    if (isObject(name)) return '';

    if (path && path.match(FULL_SRC_URL)) return `${path}/${file}/${name}`;

    return getImage(`files/${file}/${name}`);
  }

  return name;
};

export const getSVGPromise = async (path, options) => {
  if (!path || !window.fetch) return false;

  const { isHeaderLogo } = options || {};

  const response = await fetch(path);

  if (isHeaderLogo && !response.headers.get('content-type').includes('image/svg')) {
    return false;
  }

  const svg = await response.text();

  return new DOMParser()
    .parseFromString(svg, 'text/html')
    .querySelector('svg');
};

export const getSVG = (path, preload, options) => {
  const { id } = options || {};

  // eslint-disable-next-line no-param-reassign
  if (isStatic() && path.slice(0, 2) === '//') path = path.slice(1);

  getSVGPromise(path)
    .then((dom) => (typeof preload === 'function' ? preload(dom) : dom))
    .then((dom) => {
      if (dom) {
        const cloneDOM = dom.cloneNode(true);
        const div = document.createElement('div');

        if (id) {
          div.setAttribute('id', id);

          // eslint-disable-next-line unicorn/prefer-query-selector
          const currentDiv = document.getElementById(id);

          if (currentDiv) {
            currentDiv.remove();
          }
        }

        div.classList.add('hidden');
        div.append(cloneDOM);
        document.body.insertBefore(div, document.body.firstChild);
      }
    })
    .catch((error) => console.error(error));
};

export const renderImg = (props) => {
  const {
    polyfill = true,
    type = 'cover',
    sizes = {},
    innerRef = null,
    alt,
    title,
    hoverEffects,
    showEffect,
    ...nativeProps
  } = props;

  if (!polyfill) {
    return (
      <img
        ref={innerRef}
        alt={alt || null}
        title={title || null}
        {...nativeProps}
      />
    );
  }

  let {
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    style = {},
    src,
    // eslint-disable-next-line prefer-const
    ...otherProps
  } = nativeProps;

  // eslint-disable-next-line no-restricted-syntax
  if (!('objectFit' in document.documentElement.style)) {
    const {
      nativeW = 100,
      nativeH = 100,
    } = sizes;

    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    style = { ...style, background: `url("${src}") no-repeat 50% / ${type}` };
    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${nativeW}' height='${nativeH}'%3E%3C/svg%3E`;
  }

  return (
    <img
      src={src}
      style={style}
      alt={alt || null}
      ref={innerRef}
      title={title || null}
      {...otherProps}
    />
  );
};

export const applyBundleUrlOverload = (path) => {
  // may be setuped by backend app:
  // window['BUNDLE_URL'] = '/bundle/34089';
  if (!window.BUNDLE_URL || !path.startsWith('/assets')) return path;

  return path.replace('/assets', window.BUNDLE_URL);
};

export const makeLinkAbsolute = (link, host) => {
  if (!link || link.includes('http')) return link;

  return host + link;
};

export const convertFileUrlToAbsolute = (file) => {
  const fileServiceHost = file.isStatic ? STATIC_SERVICE : FS_API_SERVICE;

  // eslint-disable-next-line no-param-reassign
  file.url = makeLinkAbsolute(file.url, fileServiceHost);
  // eslint-disable-next-line no-param-reassign
  file.uploadUrl = makeLinkAbsolute(file.uploadUrl, fileServiceHost);
  // eslint-disable-next-line no-param-reassign
  file.thumbnailUrl = makeLinkAbsolute(file.thumbnailUrl, fileServiceHost);

  return file;
};

export const isCached = (src) => {
  const image = new Image();

  image.src = src;

  const { complete } = image;

  image.src = '';

  return complete;
};
