const FetchError = function (message) {
  if (Object.defineProperty) {
    Object.defineProperty(this, 'message', {
      value: message || '',
      enumerable: false,
    });
  } else {
    this.message = message;
  }

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, FetchError);
  }
};

FetchError.prototype = new Error('FetchError');
FetchError.prototype.name = 'FetchError';

export default FetchError;
