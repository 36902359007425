import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Scroll from '@sp/component/scroll';
import { usePrevious } from '@sp/core/helper/hooks';
import useEvent from '@sp/core/helper/hooks/useEvent';

const InputWithScroll = ({
  placeholder,
  autoComplete = 'off',
  maxLength,
  spellCheck = 'false',
  onChange,
  onBlur,
  onFocus,
  value = '',
  name,
  disabled,
}) => {
  const textarea = useRef(null);
  const inputWrapper = useRef(null);
  const scroll = useRef(null);

  const updateTextareaScroll = useEvent(() => {
    if (!textarea.current || !inputWrapper.current) return;

    const element = textarea.current;

    if (inputWrapper.current.scrollHeight === element.scrollHeight) return;

    const draftScrollTop = scroll.current?.getScrollTop() || 0;

    element.style.height = `${inputWrapper.current.scrollHeight}px`;
    element.style.height = `${element.scrollHeight}px`;

    if (scroll.current?.getScrollTop() !== draftScrollTop) {
      scroll.current?.scrollTop(draftScrollTop);
    }
  });

  useEffect(() => {
    if (textarea?.current?.scrollHeight !== inputWrapper?.current?.scrollHeight) {
      updateTextareaScroll();
    }
  }, [updateTextareaScroll]);

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== value) {
      updateTextareaScroll();
    }
  }, [value, prevValue, updateTextareaScroll]);

  return (
    <div
      className={cx('input-box', '_textarea')}
      ref={inputWrapper}
    >
      <Scroll ref={scroll} dark>
        <textarea
          placeholder={placeholder}
          className="input2 textarea"
          ref={textarea}
          autoComplete={autoComplete}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          spellCheck={spellCheck}
          maxLength={maxLength}
          disabled={disabled}
        />
      </Scroll>
    </div>
  );
};

InputWithScroll.propTypes = {
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  spellCheck: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InputWithScroll;
