import FetchError from '../FetchError';

/**
 * Create a FetchError instance with the response object
 * @param {{ request: Object, response: Object }} error
 * @returns {FetchError}
 */
const makeErrorResponse = (error) => {
  const { request, response } = error || {};
  const errorInstance = new FetchError(response?.status
    ? `Request failed with status code ${response.status}`
    : 'Network error');

  errorInstance.name = 'FetchError';

  if (request) errorInstance.request = request;
  if (response) errorInstance.response = response;
  if (!response?.status) errorInstance.isNetworkError = true;

  return errorInstance;
};

export default makeErrorResponse;
