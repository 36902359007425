import React from 'react';
import PropTypes from 'prop-types';

const ButtonText = ({
  text,
  style,
}) => <span style={style}>{text}</span>;

ButtonText.propTypes = {
  text: PropTypes.node,
  style: PropTypes.shape({}),
};

export default ButtonText;
