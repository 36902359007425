import compose from '../compose';

import toPreColor from './toPreColor';
import toValidColor from './toValidColor';

/**
 * Get lightened preColor
 * @param {String} value A color string
 * @param {Number|String} magnitude Amount of lightness applied
 * @param {Object} collection
 */
const lighten = (value, magnitude, collection) => {
  const args = [
    toPreColor,
    (c) => c.lighten(Number(magnitude) / 100).rgb(),
    (color) => toValidColor(color, collection),
  ];

  return compose(...args)(value);
};

export default lighten;
