import { readBody } from './createXHR';
import getResponseHeaders from './getResponseHeaders';

const textTypeList = new Set(['text/plain', 'text/html']);

/**
 * Prepare response
 * @param {XMLHttpRequest} request
 * @param {Object} config
 * @returns {Promise<{}>}
 */
const prepareResponse = async (request, config) => {
  const response = {};
  const isTextPlain = textTypeList.has(config?.headers?.Accept);
  const isImage = config?.headers?.['Content-Type']?.includes?.('image');

  response.status = request.status;
  response.statusText = request.statusText;
  response.headers = getResponseHeaders(request);
  response.request = request;
  response.config = config;

  if (!isImage) {
    const responseBody = readBody(request);

    if (responseBody) {
      response.data = isTextPlain ? responseBody : await JSON.parse(responseBody);
    } else {
      response.data = null;
    }
  }

  return response;
};

export default prepareResponse;
