import uniqueId from '@sp/core/helper/lodash/uniqueId';

export const PHONE = 'phone';
export const NOTE = 'note';
export const TABLET = 'tablet';
export const LAPTOP = 'laptop';
export const QUAD = 'quad';
export const DESKTOP = 'desktop';
export const ULTRA = 'ultra';

const laptopBreakpoint = () => window.innerWidth > 1024;

/**
 phone: 320,
 tablet: 768,
 laptop: 1039,
 desktop: 1366,
 quad: 1920
 * @param element
 * @return {number}
 */

export const DEVICES = {
  PHONE,
  NOTE,
  TABLET,
  DESKTOP,
  LAPTOP,
  QUAD,
  ULTRA,
};

export const DEVICE_POINTS = {
  get [PHONE]() {
    return 270;
  },
  get [TABLET]() {
    return laptopBreakpoint() ? 593 : 767;
  },
  get [LAPTOP]() {
    return laptopBreakpoint() ? 594 : 1039;
  },
  get [DESKTOP]() {
    return 1366;
  },
  get [QUAD]() {
    return 1920;
  },
};

export const PREVIEW_DEVICES = {
  ...DEVICE_POINTS,
  [LAPTOP]: 1024,
  [DESKTOP]: 1366,
};

export const D = {
  ...DEVICE_POINTS,
  [LAPTOP]: 1024,
  [DESKTOP]: 1200,
  [QUAD]: 1440,
};

export const VERTICAL_INDEX = {
  [PHONE]: 0.3,
  [TABLET]: 0.5,
  [LAPTOP]: 0.5,
  [DESKTOP]: 1,
  [QUAD]: 1.2,
};

export const VERTICAL_INDEX_V2 = {
  [PHONE]: 0.6,
  [TABLET]: 0.6,
  [LAPTOP]: 0.75,
  [DESKTOP]: 1,
  [QUAD]: 1.25,
  [ULTRA]: 1.25,
};

export const VERTICAL_INDEX_V3 = {
  ...VERTICAL_INDEX_V2,
  [PHONE]: 0.4,
};

export const VERTICAL_INDEX_V4 = {
  [PHONE]: 0.4,
  [TABLET]: 0.6,
  [LAPTOP]: 1,
  [DESKTOP]: 1,
  [QUAD]: 1,
};

export const MIN_POPOVER_OFFSET = 20;

export const DEVICE_SIZES = {
  [PHONE]: [0, 767],
  [TABLET]: [768, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, 2560],
};

export const getDeviceSizes = (note = false, fix = true) => {
  const sizes = { ...DEVICE_SIZES };

  if (note) {
    sizes[PHONE] = [0, 479];
    sizes[NOTE] = [480, 767];
  }
  if (fix) {
    sizes[TABLET] = [768, 1024];
    sizes[LAPTOP] = [1040, 1366];
  }

  return sizes;
};

export const getDeviceType = (
  width = window.innerWidth,
  note = false,
  fix = true
) => {
  const sizes = getDeviceSizes(note, fix);

  // eslint-disable-next-line guard-for-in
  for (const device in sizes) {
    const [min, max] = sizes[device];

    if (width >= min && width <= max) return device;
  }

  return QUAD;
};

/**
 * Get ratio applied to item width for device types
 * @param {String} device
 * @param {Array} deviceTypes
 * @returns {Number} Device coefficient for item width
 */
export const getDeviceRatio = (device, deviceTypes) => {
  const DEFAULT_RATIO = 1;

  return deviceTypes.includes(device)
    ? VERTICAL_INDEX[device]
    : DEFAULT_RATIO;
};

export const generateUUID = () => {
  try {
    return window.crypto.randomUUID();
  } catch {
    return uniqueId();
  }
};
