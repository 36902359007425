import isString from '@sp/core/helper/lodash/isString';

import { CSS_VAR_KEY_REGEX } from '../constants';

import extractVar from './extractVar';

/**
 * @param {string} value
 * @return {boolean}
 */
const isCssVar = (value) => isString(value) && CSS_VAR_KEY_REGEX.test(value);

/**
 * @param {string} name
 * @param {Object} collection Css root variables source
 * @return {string}
 */
const recursiveVarFind = (name, collection) => {
  const stack = [name];

  while (stack.length > 0) {
    const stackElement = stack.shift();
    const cssVar = collection[stackElement];
    const cssVarName = !cssVar && cssVar !== 0 ? stackElement : extractVar(cssVar);

    if ((cssVar || cssVar === 0) && isCssVar(cssVarName)) {
      stack.push(cssVarName);
    } else {
      return cssVar || cssVar === 0 ? cssVar : stackElement;
    }
  }
};

export default recursiveVarFind;
