import checkRetryable from '@sp/core/api/fetch/utils/checkRetryable';
import makeErrorResponse from '@sp/core/api/fetch/utils/makeErrorResponse';
import makeRetryRequest from '@sp/core/api/fetch/utils/makeRetryRequest';

/**
 * Get handlers for request
 * @param {String} url
 * @param {String} method
 * @param {FormData|File|Object=} data
 * @param {Object} config
 * @param {Number|undefined} timeoutId
 * @param {Boolean=} retryable
 * @param {Function} createRequest
 * @returns {{handleNetworkError: ((function(*): (Promise<*>|undefined))|*), handleErrorStatus: ((function(*): (Promise<*>|undefined))|*)}}
 */
const getHandlers = ({
  url,
  method,
  data,
  config,
  timeoutId,
  retryable,
  createRequest,
}) => {
  const handleErrorStatus = (error) => {
    const isRetryable = !timeoutId && checkRetryable(retryable, error);

    if (isRetryable) {
      return makeRetryRequest(error, {
        url,
        method,
        data,
        config,
      }, createRequest);
    }

    throw error;
  };

  const handleNetworkError = (error) => {
    const isRetryable = !timeoutId && checkRetryable(retryable, error);

    if (isRetryable) {
      return makeRetryRequest(error, {
        url,
        method,
        data,
        config,
      }, createRequest);
    }

    throw makeErrorResponse(error);
  };

  return {
    handleErrorStatus,
    handleNetworkError,
  };
};

export default getHandlers;
