import { isNotStringOrEmpty } from '@sp/core/helper/checkers';
import countriesMap from '@sp/fixtures/json/country.json';

import { getUserLocation } from '../../utils';

let userGeoLocation;

export const initLocation = async () => {
  const data = await getUserLocation();

  userGeoLocation = data?.country_code || '';
};

const getCountryByUserLocation = () => {
  if (isNotStringOrEmpty(userGeoLocation) || !Array.isArray(countriesMap)) {
    return;
  }

  return countriesMap.find(
    (country) => country.code.toLowerCase() === userGeoLocation.toLowerCase()
  );
};

export default getCountryByUserLocation;
