import PAGE_TYPES, {
  NON_PAGE_TYPES,
  PAGE_TYPE_BLOG_POST,
  PAGE_TYPE_COOKIE_POLICY,
  PAGE_TYPE_PAGE,
  PAGE_TYPE_PRODUCT,
} from '@sp/core/constants/pageTypes';

/**
 * @typedef {Object} Menu
 * @property {Array} list
 * @property {Array} outOfPlan
 * @property {Array} unlinked
 * @property {Array} hidden
 */

export const MENU_GROUP = {
  LIST: 'list',
  UNLINKED: 'unlinked',
  OUT_OF_PLAN: 'outOfPlan',
};

export const MENU_ITEM_TYPE = {
  FOLDER: PAGE_TYPES.FOLDER,
  LINK: PAGE_TYPES.LINK,
  PAGE: PAGE_TYPES.PAGE,
  COOKIES_POLICY: PAGE_TYPES.COOKIE_POLICY,
  ECOMMERCE: PAGE_TYPES.CATALOG,
  BLOG: PAGE_TYPES.BLOG,
  BOOKING: PAGE_TYPES.BOOKING,
};

export const MENU_GROUPS = Object.values(MENU_GROUP);

const checkFilter = (filter, type) => {
  if (!filter) return true;

  const {
    include,
    exclude,
  } = filter;

  if (include) return include.includes(type);
  if (exclude) return !exclude.includes(type);

  return true;
};

const traverseMenuItem = (menuItem, res, group, itemTypes) => {
  if (!menuItem) return;

  const stack = [menuItem];

  while (stack.length > 0) {
    const stackElement = stack.shift();

    if (!stackElement) continue;

    const {
      children,
      type,
    } = stackElement;

    if (checkFilter(itemTypes, type)) {
      res.push({
        ...stackElement,
        group,
      });
    }

    if (children) {
      children.forEach((c) => stack.push(c));
    }
  }
};

/**
 * Get array of menu items.
 *
 * @param {Object} groups - Filter by groups
 * f.e. {exclude: [MENU_GROUP.LIST], include: [MENU_GROUP.UNLINKED]}
 * @param {Object} itemTypes - Filter by item types
 * f.e. {exclude: [PAGE_TYPE_FOLDER], include: [PAGE_TYPE_PAGE]}
 * @param {{}} menu - All menu items
 * @return {Array.<Object>}
 */
export const getMenuItems = (
  groups,
  itemTypes,
  menu
) => {
  const res = [];

  Object.keys(menu).forEach((key) => {
    const group = menu[key];

    if (group && checkFilter(groups, key)) {
      group.forEach((p) => traverseMenuItem(p, res, key, itemTypes));
    }
  });

  return res;
};

export const getPagesList = (groups, menu) => getMenuItems(
  groups,
  { exclude: [...NON_PAGE_TYPES, PAGE_TYPE_BLOG_POST, PAGE_TYPE_PRODUCT] },
  menu
);

export const getPlanPages = (menu) => getPagesList(
  { include: [MENU_GROUP.LIST, MENU_GROUP.UNLINKED] },
  menu
);

/**
 * Recursively find page by hash
 * among list and its children
 * @param {string} hash page hash to find
 * @param {array} list pages
 * @return {array|undefined}
 */
const findPageByHash = (hash, list) => list.find((page) => {
  if (page.hash === hash) {
    return true;
  }
  if (page.children && page.children.length > 0) {
    findPageByHash(hash, page.children);
  }
});

/**
 * Get page structure from Menu list
 * @param {string} hash page hash to find
 * @param {object} menu
 * @return {{}}
 */
export const getPageFromMenuList = (hash, menu) => {
  const pages = getPagesList(null, menu);

  return findPageByHash(hash, pages) || {};
};

const projectInfo = () => {
  const urlArr = window.location.href.split('/');
  const SCREEN = urlArr[3];
  const ID = urlArr[4];
  const PAGE = (urlArr[5] || '').replaceAll(/#.*/gim, '');

  return {
    SCREEN,
    ID,
    PAGE,
  };
};

/**
 * Set menu item type equal to 'page'
 * if there is no type value
 */
const fixTypeInMenuItems = (menuItems = []) => menuItems
  .filter(Boolean)
  .map((menuItem) => {
    const type = menuItem.type || PAGE_TYPE_PAGE;
    const { children } = menuItem;

    return {
      ...menuItem,
      children: children ? fixTypeInMenuItems(children) : children,
      type,
    };
  });

/**
 * Fix type value in menu item
 */
export const fixUndefinedMenuItemsType = (menu) => Object.entries(menu)
  .reduce((previousResult, [listKey, itemsArr]) => {
    const result = previousResult;

    result[listKey] = fixTypeInMenuItems(itemsArr);

    return result;
  }, {});

const findCookiePage = (menuItems = []) => {
  const isCookiePage = (menuItem) => menuItem?.type === PAGE_TYPE_COOKIE_POLICY;

  return menuItems.find((element) => isCookiePage(element));
};

/**
 * Find cookie page hash in menu. We need determine
 * it to permanent disable cookieHash for editting.
 * It may be deleted later when editting is enabled
 * @param {{}} menu pages menu
 */
export const findCookiePageHashInMenu = (menu) => {
  const menuItems = getMenuItems(null, null, menu);
  const childrenItems = menuItems.map((page) => page.children);
  const cookiePage = findCookiePage(menuItems) || findCookiePage(...childrenItems);

  return cookiePage ? cookiePage.hash : null;
};

const PROJECT = {
  get SCREEN() {
    return projectInfo().SCREEN;
  },
  get ID() {
    return projectInfo().ID;
  },
  get PAGE() {
    return projectInfo().PAGE;
  },
};

export default PROJECT;
