import punycodeConverter from '@sp/core/helper/punycodeConverter';

import { VALIDATION_TYPES } from './constants';
import { composeValidators } from './utils';
import validationRules from './validationRules';

/**
 * If there is NO error then validator must return undefined.
 * Otherwise validator must return string(VALIDATION_TYPES)
 * OR object { type: string(VALIDATION_TYPES), meta?: Object }.
 * meta object is used later to get advanced validation message.
 */

export const matches = (
  regex,
  type = VALIDATION_TYPES.MATCHES,
  maxLength = 255
) => (value) => (regex.test(value) && value?.length <= maxLength
  ? undefined
  : {
    type,
    meta: {
      regex,
      value,
    },
  });

export const required = (value) => (value ? undefined : {
  type: VALIDATION_TYPES.REQUIRED,
  meta: { value },
});

export const email = matches(
  validationRules.typesRegex.email,
  VALIDATION_TYPES.EMAIL
);

export const phone = matches(
  validationRules.typesRegex.phone,
  VALIDATION_TYPES.PHONE
);

export const url = (value) => {
  const panycode = punycodeConverter.linkToUnicode(value);
  const urlValue = panycode || value;

  return matches(
    validationRules.typesRegex.url,
    VALIDATION_TYPES.URL,
    2000
  )(urlValue);
};

export const text = matches(
  validationRules.typesRegex.username,
  VALIDATION_TYPES.EMAIL
);

const notRequiredMatch = (validator, type, maxLength) => (value) => (value
  ? matches(validator, type, maxLength)(value)
  : undefined);

export const maxlengthUrl = (value, maxlength = 255) => (value.length < maxlength
  ? undefined
  : {
    type: VALIDATION_TYPES.URL,
    meta: {
      maxlength,
      value,
    },
  });

export const emailForm = composeValidators(required, email);

export const urlForm = composeValidators(required, maxlengthUrl, url);

export const notLongerThan = (num) => (value) => (!value || validationRules.typesRegex.maxlength(value, num)
  ? undefined
  : {
    type: VALIDATION_TYPES.MAX_LENGTH,
    meta: { number: num },
  });

const descriptionNotLongerThan = (num) => (value) => ((!value || validationRules.typesRegex.maxlength(value, num))
  ? undefined
  : {
    type: VALIDATION_TYPES.MAX_LENGTH,
    meta: { number: num },
  });

export const minLengthValue = (num) => (value) => (!value || validationRules.typesRegex.minlength(value, num)
  ? undefined
  : {
    type: VALIDATION_TYPES.MIN_LENGTH,
    meta: { number: num },
  });

export const advancedFormTitle = composeValidators(
  required,
  matches(validationRules.typesRegex.titleMessage, VALIDATION_TYPES.LATIN),
  notLongerThan(140)
);

export const onSubmitMessageTitle = composeValidators(
  required,
  matches(validationRules.typesRegex.onSubmitMessage, VALIDATION_TYPES.MATCHES),
  notLongerThan(140)
);

export const onSubmitMessageDescription = composeValidators(
  descriptionNotLongerThan(255),
  notRequiredMatch(validationRules.typesRegex.onSubmitMessageDescription, VALIDATION_TYPES.MATCHES)
);

export const settingsFormName = composeValidators(
  required,
  matches(validationRules.typesRegex.latin, VALIDATION_TYPES.LATIN)
);

export const formValidationMessage = composeValidators(
  required,
  notLongerThan(255),
  matches(validationRules.typesRegex.validateMessage, VALIDATION_TYPES.VALIDATION_MESSAGE, 256)
);

export const advancedFormPromo = composeValidators(
  notRequiredMatch(validationRules.typesRegex.noSpace, VALIDATION_TYPES.MATCHES),
  notRequiredMatch(validationRules.typesRegex.latin, VALIDATION_TYPES.LATIN),
  notLongerThan(50)
);

export const advancedFormDescription = composeValidators(
  descriptionNotLongerThan(255),
  notRequiredMatch(validationRules.typesRegex.description, VALIDATION_TYPES.DESCRIPTION, 255)
);

export const countdownTimeUpMessage = composeValidators(
  required,
  notLongerThan(500),
  notRequiredMatch(validationRules.typesRegex.anyTextWithoutEnter, VALIDATION_TYPES.TIME_UP_MESSAGE, 501)
);

export const mapMarkerDescription = composeValidators(
  notLongerThan(200)
);

export const payPalAccount = (value) => {
  if (!value) return;

  return matches(validationRules.typesRegex.payPalAccount, VALIDATION_TYPES.PAYPAL_ACCOUNT, 201)(value);
};

export const layoutFormTitle = composeValidators(
  matches(validationRules.typesRegex.latin, VALIDATION_TYPES.LATIN),
  notLongerThan(140)
);

export const layoutFormPlaceholder = composeValidators(
  notLongerThan(140)
);
