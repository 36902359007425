import React from 'react';
import cx from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import omit from '@sp/core/helper/lodash/omit';

import InputWithScroll from '../InputWithScroll';

const InputHeader = ({
  withScroll,
  placeholder: plHolder,
  multiple,
  innerRef,
  inputClass,
  restProps,
  id,
}) => {
  const Element = multiple ? 'textarea' : 'input';
  const {
    maxLength,
    autoComplete,
    spellCheck,
    onChange,
    onBlur,
    onFocus,
    value,
    name,
    disabled,
  } = restProps;
  const placeholder = value ? null : ((plHolder && i18next.exists(plHolder)) ? i18next.t(plHolder) : plHolder);

  return withScroll ? (
    <InputWithScroll
      placeholder={placeholder}
      maxLength={maxLength}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      name={name}
      ref={innerRef}
      disabled={disabled}
    />
  ) : (
    <Element
      type={multiple ? null : 'text'}
      placeholder={placeholder}
      className={cx(inputClass, { textarea: multiple })}
      ref={innerRef}
      id={id}
      {...omit(restProps, [
        'i18n',
        'lng',
        'i18nOptions',
        'defaultNS',
        'reportNS',
        'withReset',
        'dynamic',
      ])}
    />
  );
};

InputHeader.propTypes = {
  inputClass: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  innerRef: PropTypes.any,
  withScroll: PropTypes.bool,
  restProps: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

export default InputHeader;
