const {
  MODE,
  NODE_ENV: ENV,
  VERSION,
  TIME,
  CI_PIPELINE_ID,
  SERVER,
} = process.env;

const local = MODE === 'local';
const prod = ENV === 'production';
const dev = ENV === 'development';
const builder = NODE_ENV === 'builder';
const publish = NODE_ENV === 'publish';
const stage = SERVER === 'stage';
const testEnv = dev || stage;

export const is = {
  stage,
  testEnv,
  local,
  prod,
  dev,
  builder,
  publish,
  get review() {
    return window.location.host.split('.')[1] === 'review';
  },
  get screenshot() {
    return window.location.search.includes('screenshot=1');
  },
  get debug() {
    return window.location.search.includes('debug=1');
  },
};

if (CI_PIPELINE_ID && CI_PIPELINE_ID !== 'null') {
  window.CI_PIPELINE_ID = CI_PIPELINE_ID;
}

window.__VERSION__ = () => {
  console.log(`
    Application Version: ${VERSION}
		Deployment Time: ${TIME}
		CI Pipeline ID: ${CI_PIPELINE_ID}
  `);
};

export default is;
