export const RULES = {
  email: /(^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$)|(^\s*$)/i,
  password: /[^\s-]|[A-z]|[àĀ-ž]|\d|[!"#$%&'()*+-/:;=?@[\]^_{|}~‘’]/gm
};

export const PASSWORD_LENGTH = 32;

export const VALIDATION_LENGTH = {
  emailMin: 5,
  emailMax: 254,
  passwordMin: 6,
  passwordMax: 255,
}

export const VALIDATION_ERRORS = {
  email: 'Please enter valid email address.',
  password: 'Please enter valid password.',
  emailEmpty: 'Please enter your email address.',
  passwordEmpty: 'Please enter your password.',
  emailLessThan: `Email is too short`,
  emailMoreThan: `Email is too long (max is ${VALIDATION_LENGTH.emailMax} characters).`,
  passwordLessThan: `Please enter at least ${VALIDATION_LENGTH.passwordMin} characters.`,
  passwordMoreThan: `Max password length is ${VALIDATION_LENGTH.passwordMax} characters.`,
};

export const REQUIRED_ERRORS = {
  email: 'Please enter your email address.',
  password: 'Please enter your password.',
};

export const FALLBACK_MESSAGE = 'Network Error';
