/**
 * Check if the request is retryable
 * @param {Boolean} retryable
 * @param {Object} error
 * @returns {boolean}
 */
const checkRetryable = (retryable, error) => retryable
  && (!error?.response || (error.response.status >= 500 && error.response.status < 600));

export default checkRetryable;
