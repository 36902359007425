import isString from '@sp/core/helper/lodash/isString';

import toCssRootKey from './toCssRootKey';

/**
 * Convert base object to collection of variables set to css-root
 * @param {Object|String} source css-root source oject
 * @param {Object|String=} payload
 */
const toCssRootKeys = (
  source,
  payload
) => {
  if (isString(source)) {
    return { [toCssRootKey(source)]: payload };
  }

  return Object.keys(source).reduce(
    (acc, key) => {
      acc[toCssRootKey(key)] = source[key];

      return acc;
    },
    {}
  );
};

export default toCssRootKeys;
