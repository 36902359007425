import createRootGetter from './createRootGetter';
import recursiveVarFind from './recursiveVarFind';

/**
 * @param {string} name
 * @param {Object} collection
 * @return {Object|string}
 */
const getRoot = (name, collection) => {
  const getter = createRootGetter((key) => recursiveVarFind(key, collection));

  return name ? getter(name) : collection;
};

export default getRoot;
