import '@sp/core/polyfills/Array';
import './fixBrowser';

window.$App = {};

const container = document.createElement('div');

container.setAttribute('id', 'app');

document.body.append(container);
