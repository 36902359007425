/**
 * ATTENTION!
 * Please add validation types in alphabetical order!
 * Thanks!
 */
export const VALIDATION_TYPES = {
  EMAIL: 'email',
  FACEBOOK_PAGE_ID: 'facebookPageId',
  PHONE: 'phone',
  MATCHES: 'matches',
  MAX_LENGTH: 'maxlength',
  MIN_LENGTH: 'minlength',
  REQUIRED: 'required',
  URL: 'url',
  YOUTUBE_ID: 'youtubeId',
  LATIN: 'latin',
  CITY: 'city',
  ADDRESS: 'address',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  STATE: 'state',
  ZIP: 'zip',
  DESCRIPTION: 'description',
  VIDEO: 'video',
  TIME_UP_MESSAGE: 'timeUpMessage',
  VALIDATION_MESSAGE: 'validationMessage',
  PAYPAL_ACCOUNT: 'payPalAccount',
};

export const VALIDATION_RANGE = {
  [VALIDATION_TYPES.EMAIL]: {
    min: 6,
    max: 254,
  },
  [VALIDATION_TYPES.PHONE]: {
    min: 5,
    max: 25,
  },
  [VALIDATION_TYPES.VALIDATION_MESSAGE]: {
    min: 1,
    max: 255,
  },
};

export const HALF_SPACE_SYMBOL = '\u2009';
