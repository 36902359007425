import { VALIDATION_TYPES } from './constants';
import validationRules from './validationRules';

/**
 * ATTENTION!
 * Please add validation messages in alphabetical order!
 * Thanks!
 */
export const messages = {
  DEFAULT: validationRules.message.default,
  [VALIDATION_TYPES.EMAIL]: validationRules.message.email,
  [VALIDATION_TYPES.VIDEO]: 'se.tb.background_video-validation-error',
  [VALIDATION_TYPES.FACEBOOK_PAGE_ID]: 'sh.vr.message.facebook-page-id',
  [VALIDATION_TYPES.YOUTUBE_ID]: 'sh.vr.message.youtube.video-not-exist',
  [VALIDATION_TYPES.PHONE]: validationRules.message.phone,
  [VALIDATION_TYPES.CITY]: validationRules.message.city,
  [VALIDATION_TYPES.ADDRESS]: validationRules.message.address,
  [VALIDATION_TYPES.FIRSTNAME]: validationRules.message.firstname,
  [VALIDATION_TYPES.LASTNAME]: validationRules.message.lastname,
  [VALIDATION_TYPES.ZIP]: validationRules.message.zip,
  [VALIDATION_TYPES.STATE]: validationRules.message.state,
  [VALIDATION_TYPES.REQUIRED]: {
    // You can set different messages for different fields
    DEFAULT: validationRules.message.required,
    [VALIDATION_TYPES.EMAIL]: validationRules.message.requiredEmail,
  },
  // Function can be used to return dynamic validation message
  [VALIDATION_TYPES.URL]: ({
    error,
  }) => {
    const [type] = Object.keys(error.meta);

    switch (type) {
      case 'maxlength': {
        const getMessage = validationRules.message[type][VALIDATION_TYPES.URL];

        return getMessage(
          error.meta.value.length,
          error.meta.maxlength
        );
      }
      default: {
        return validationRules.message.url;
      }
    }
  },
  [VALIDATION_TYPES.MAX_LENGTH]: ({ error }) => validationRules.message.maxlength.default(null, error.meta.number),
};
