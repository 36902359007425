import isEmpty from '@sp/core/helper/lodash/isEmpty';
import isString from '@sp/core/helper/lodash/isString';

import { CSS_VAR_KEY_REGEX, CSS_VAR_REGEX } from '../constants';

import toRGB from './color/toRGB';
import compose from './compose';

/**
 * @param {string} source
 * @param {RegExp} regExp
 * @return {string | null}
 */
const matchOnlyKey = (source, regExp) => {
  const matchedKeys = source.match(regExp);

  return matchedKeys && matchedKeys[0];
};

const toCssRootValue = compose(toRGB, (val) => (isString(val) ? val.trim() : val));

/**
 * @param {function} getter
 * @return {function}
 */
const createRootGetter = (getter) =>
  /**
   *
   * @param name in callback
   * @return {string}
   */
// eslint-disable-next-line implicit-arrow-linebreak
  (name) => {
    if (!isString(name)) return name;

    const key = matchOnlyKey(name, CSS_VAR_KEY_REGEX);
    const cssVar = matchOnlyKey(name, CSS_VAR_REGEX);
    const value = getter(key);

    if (!key || key === value) return name;
    if (!cssVar || cssVar === name) return toCssRootValue(value);
    if (isEmpty(value)) return name;

    return name.replace(cssVar, `${value}`);
  };

export default createRootGetter;
