import i18next from 'i18next';

export const IMAGE_FILEMANAGER_MAX_RESOLUTION = {
  width: 8000,
  height: 8000,
};
export const IMAGE_LIBRARY_MAX_RESOLUTION = {
  width: 8000,
  height: 8000,
};
const ALLOWED_FILEMANAGER_EXTENSIONS = [
  'jpeg',
  'png',
  'bmp',
  'jpg',
  'gif',
  'svg',
];
const ALLOWED_GALLERY_EXTENSIONS = ['jpeg', 'png', 'bmp', 'jpg', 'gif'];

export const ALLOWED_PAGE_SETTINGS_EXTENSIONS = ['jpeg', 'png', 'bmp', 'jpg'];
export const ALLOWED_PAGE_SETTINGS_ACCEPT = 'image/jpeg, image/png, image/bmp, image/jpg';
export const ALLOWED_IMAGE_LIBRARY_PAGE_SETTINGS_EXTENSIONS = ['jpeg', 'png', 'jpg', 'jfif'];
export const ALLOWED_IMAGE_LIBRARY_PAGE_SETTINGS_ACCEPT = ['image/jpeg', 'image/png', 'image/jpg', 'image/jfif'];
export const ALLOWED_IMAGE_LIBRARY_EXTENSIONS = ['jpeg', 'png', 'svg', 'jpg', 'gif', 'jfif'];
export const ALLOWED_IMAGE_LIBRARY_ACCEPT = ['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml', 'image/jpg', 'image/gif', 'image/jfif'];

export const ALLOWED_FAVICON_EXTENSIONS = ['svg', 'jpeg', 'png', 'bmp', 'jpg'];

export const ALLOWED_FAVICON_ACCEPT = 'image/svg, image/jpeg, image/png, image/bmp, image/jpg';

export const ERROR_TYPES = {
  EXTENSION: 'extension',
  SIZE: 'size',
  BROKEN: 'broken',
  MAX_RESOLUTION: 'maxResolution',
};

const IMAGE_FILEMANAGER_ERRORS = {
  extension: {
    errorType: ERROR_TYPES.EXTENSION,
    message: 'se.fm.err-string_mietype_description',
    title: 'se.fm.err-string_mietype_mesage',
  },
  size: {
    errorType: ERROR_TYPES.SIZE,
    message: 'se.fm.err-string_filesize_description',
    title: 'se.fm.err-string_filesize_mesage',
  },
  broken: {
    errorType: ERROR_TYPES.BROKEN,
    message: 'se.mp.gallery_error_incorrect_message',
    title: 'se.mp.gallery_error_incorrect_title',
  },
  maxResolution: {
    errorType: ERROR_TYPES.MAX_RESOLUTION,
  },
};

const IMAGE_GALLERY_ERRORS = {
  extension: {
    errorType: ERROR_TYPES.EXTENSION,
    message: 'se.mp.gallery_error_type_message',
    title: 'se.mp.gallery_error_type_title',
  },
  size: {
    errorType: ERROR_TYPES.SIZE,
    message: 'se.mp.gallery_error_size_message',
    title: 'se.mp.gallery_error_size_title',
  },
  broken: {
    errorType: ERROR_TYPES.BROKEN,
    message: 'se.mp.gallery_error_incorrect_message',
    title: 'se.mp.gallery_error_incorrect_title',
  },
};

const IMAGE_PAGE_SETTINGS_ERRORS = {
  extension: 'se.page_settings.err-string_extension',
  extensionDesc: 'se.page_settings.err-string_extension_desc',
  size: (size) => i18next.t('se.page_settings.err-string_size', {
    size: size / 1024 / 1024,
  }),
  broken: 'se.page_settings.err-string_broken',
  maxResolution: ({ maxWidth, maxHeight } = {}) => i18next.t('se.page_settings.err-string_maxResolution', {
    resolution: `${maxWidth}x${maxHeight}`,
  }),
};

const IMAGE_LIBRARY_ERRORS = {
  extension: {
    errorType: ERROR_TYPES.EXTENSION,
    title: 'se.fm.err-string_mietype_mesage',
  },
  size: {
    errorType: ERROR_TYPES.SIZE,
    title: 'se.fm.err-string_filesize_mesage',
  },
  broken: {
    errorType: ERROR_TYPES.BROKEN,
    title: 'se.mp.gallery_error_incorrect_title',
  },
  maxResolution: {
    errorType: ERROR_TYPES.MAX_RESOLUTION,
    title: 'se.fm.err-string_max_resolution_message',
  },
};

const FAVICON_ERRORS = {
  extension: 'se.favicon.err-string_extension',
  size: (size) => i18next.t('se.favicon.err-string_size', {
    size: size / 1024 / 1024,
  }),
  broken: 'se.favicon.err-string_broken',
  minResolution: ({ minWidth, minHeight } = {}) => i18next.t('se.favicon.err-string_minResolution', {
    resolution: `${minWidth}x${minHeight}`,
  }),
  maxResolution: ({ maxWidth, maxHeight } = {}) => i18next.t('se.favicon.err-string_maxResolution', {
    resolution: `${maxWidth}x${maxHeight}`,
  }),
};

export const IMAGE_MAX_SIZE_IN_MB = {
  FAVICON: 1,
  FILEMANAGER: 25,
  GALLERY: 15,
  PAGE_SETTINGS: 5,
  IMAGE_LIBRARY: 20,
};

const IMAGE_LIBRARY_PAGE_SETTINGS_ERRORS = {
  extension: {
    errorType: ERROR_TYPES.EXTENSION,
    title: 'se.image_library_page_settings.err-string_extension',
  },
  size: {
    errorType: ERROR_TYPES.SIZE,
    title: i18next.t('se.page_settings.err-string_size', {
      size: IMAGE_MAX_SIZE_IN_MB.IMAGE_LIBRARY,
    }),
  },
  broken: {
    errorType: ERROR_TYPES.BROKEN,
    title: 'se.page_settings.err-string_broken',
  },
  maxResolution: {
    errorType: ERROR_TYPES.MAX_RESOLUTION,
    title: i18next.t('se.page_settings.err-string_maxResolution', {
      resolution: `${IMAGE_LIBRARY_MAX_RESOLUTION.width}x${IMAGE_LIBRARY_MAX_RESOLUTION.height}`,
    }),
  },
};

export const VALIDATION_SOURCES = {
  FAVICON: 'favicon',
  FILEMANAGER: 'filemanager',
  GALLARY: 'gallary',
  PAGE_SETTINGS: 'pageSettings',
  IMAGE_LIBRARY: 'imageLibrary',
  IMAGE_LIBRARY_PAGE_SETTINGS: 'imageLibraryPageSettings',
};

export const IMAGE_FILEMANAGER_VALIDATION = {
  ERRORS: IMAGE_FILEMANAGER_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.FILEMANAGER * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_FILEMANAGER_EXTENSIONS,
  MAX_RESOLUTION: IMAGE_FILEMANAGER_MAX_RESOLUTION,
};

export const IMAGE_GALLERY_VALIDATION = {
  ERRORS: IMAGE_GALLERY_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.GALLERY * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_GALLERY_EXTENSIONS,
};

export const VIDEO_PREVIEW_IMAGE_VALIDATION = {
  ERRORS: IMAGE_GALLERY_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.GALLERY * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_GALLERY_EXTENSIONS,
  MAX_RESOLUTION: IMAGE_FILEMANAGER_MAX_RESOLUTION,
};

// seo image in page settings, post preview image in post settings (local)
export const IMAGE_PAGE_SETTINGS_VALIDATION = {
  ERRORS: IMAGE_PAGE_SETTINGS_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.PAGE_SETTINGS * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_PAGE_SETTINGS_EXTENSIONS,
  MAX_RESOLUTION: IMAGE_FILEMANAGER_MAX_RESOLUTION,
};

export const IMAGE_LIBRARY_PAGE_SETTINGS_VALIDATION = {
  ERRORS: IMAGE_LIBRARY_PAGE_SETTINGS_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.IMAGE_LIBRARY * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_IMAGE_LIBRARY_PAGE_SETTINGS_EXTENSIONS,
  MAX_RESOLUTION: IMAGE_LIBRARY_MAX_RESOLUTION,
  ACCEPT: ALLOWED_IMAGE_LIBRARY_PAGE_SETTINGS_ACCEPT,
};

export const IMAGE_LIBRARY_VALIDATION = {
  ERRORS: IMAGE_LIBRARY_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.IMAGE_LIBRARY * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_IMAGE_LIBRARY_EXTENSIONS,
  MAX_RESOLUTION: IMAGE_LIBRARY_MAX_RESOLUTION,
  ACCEPT: ALLOWED_IMAGE_LIBRARY_ACCEPT,
};

export const FAVICON_VALIDATION = {
  ERRORS: FAVICON_ERRORS,
  MAX_SIZE: IMAGE_MAX_SIZE_IN_MB.FAVICON * 1024 * 1024,
  ALLOWED_IMAGE_EXTENSIONS: ALLOWED_FAVICON_EXTENSIONS,
  MIN_RESOLUTION: {
    width: 16,
    height: 16,
  },
  MAX_RESOLUTION: {
    width: 1024,
    height: 1024,
  },
};
