/* eslint-disable no-param-reassign */

export const easeInOutCubic = (t, b, c, d) => {
  const ts = (t /= d) * t;
  const tc = ts * t;

  return b + c * (-2 * tc + 3 * ts);
};

/**
 *
 * @param {object} options
 * @param {HTMLElement} options.element
 * @param {number=} options.top
 * @param {number=} options.left
 * @param {number=} options.duration
 * @param {function=} fn
 */
const scrollTo = ({
  element,
  top = 0,
  left = 0,
  duration = 500,
}, fn = easeInOutCubic) => {
  if (!element) return;

  let frameId;
  const startTop = element.scrollTop;
  const startLeft = element.scrollLeft;
  const changeTop = top - startTop;
  const changeLeft = left - startLeft;
  const startDate = Date.now();

  const animateScroll = function () {
    const currentDate = Date.now();
    const currentTime = currentDate - startDate;

    element.scrollTop = fn(currentTime, startTop, changeTop, duration);
    element.scrollLeft = fn(currentTime, startLeft, changeLeft, duration);

    if (currentTime < duration) {
      frameId = requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = top;
      element.scrollLeft = left;
      cancelAnimationFrame(frameId);
    }
  };

  animateScroll();
};

export default scrollTo;
