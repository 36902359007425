const omit = (obj, ...args) => {
  if (!obj) return {};

  const paths = args.flat();
  let newObj = obj;

  if (typeof newObj === 'object') {
    newObj = Array.isArray(newObj) ? [...newObj] : { ...newObj };
  }

  paths.forEach((path) => {
    const pathArr = path.split('.');
    const lastKey = pathArr.pop();
    const lastObj = pathArr.reduce((acc, key) => {
      if (acc && acc[key]) {
        if (typeof acc[key] === 'object') {
          acc[key] = Array.isArray(acc[key]) ? [...acc[key]] : { ...acc[key] };
        }

        return acc[key];
      }
    }, newObj);

    if (lastObj) delete lastObj[lastKey];
  });

  return newObj;
};

export default omit;
