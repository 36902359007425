import { AUTH_METHODS } from '../constants';

import getAccessToken from './getAccessToken';

/**
 * Get headers for fetch request
 * @param {String=} method
 * @param {Object=} headers
 * @param {Object=} params
 * @returns {*&{Accept: string, 'cache-control': string, 'Content-Type': string}}
 */
const getHeaders = (method, headers, params) => {
  const { withoutAccess, withoutContentType } = params || {};
  const isNeedAuth = !withoutAccess && AUTH_METHODS.has(method) && !headers?.Authorization;

  return {
    Accept: 'application/json, text/plain, */*',
    ...!withoutContentType && { 'Content-Type': 'application/json' },
    ...isNeedAuth && { Authorization: `Bearer ${getAccessToken()}` },
    ...headers,
  };
};

export default getHeaders;
