import color from 'color';

import isString from '@sp/core/helper/lodash/isString';

import getRoot from '../getRoot';

/**
 * Function return a valid color code.
 * Use only for dynamic
 * @param {string | number[]} value - source code like #FFF or rgb(50,10,255)
 * or [60, 90, 160] or var(--variable) or rgba(var(--variable), 0.5)
 * @param {Object=} collection
 * @returns {{}} valid color code: rgba(var(--variable), 0.5) -> rgba(210, 60, 90, 0.5)
 */
const toValidColor = (value, collection) => {
  try {
    return color(isString(value) ? getRoot(value, collection) : value);
  } catch {
    // It is possible that function for a variable
    // might be defined in the collection but
    // there is no such variable defined for the template.
    return color('rgba(0,0,0,0)');
  }
};

export default toValidColor;
