/**
 * Applies fn to arg, return arg
 * @param {Function} fn Function applied to arg
 * @return {Function}
 */
const tap = (fn) => (arg) => {
  fn(arg);

  return arg;
};

export default tap;
