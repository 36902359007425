/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import emitter from '@sp/core/emitter';
import is from '@sp/core/helper/env/is';
import CssRoot from '@sp/css-root';

const DEBUG_LANG_KEY = 'uk';
const DEFAULT_LOCALE_KEY = 'en';
const SUPPORTED_LANGS = {
  en: 'en',
  uk: 'uk',
  id: 'id',
};

i18next
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: DEFAULT_LOCALE_KEY,
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,
    nsSeparator: false,

    react: {
      wait: true,
    },
  });

// @TODO: this is temp solution to test i18n integration
const numLangCode2strLang = (numLang) => (
  {
    123: SUPPORTED_LANGS.uk,
    48: SUPPORTED_LANGS.id,
  }[numLang] || DEFAULT_LOCALE_KEY
);

const getDebugTranslationFile = (translations) => Object.keys(translations)
  .reduce((prev, key) => {
    const output = { ...prev };

    output[key] = `${translations[key]} @`;

    return output;
  }, {});

export const getTranslationFile = async (key) => {
  switch (key) {
    case SUPPORTED_LANGS.id: {
      return (await import(/* webpackChunkName: "translations/id" */ './id')).default;
    }
    default: {
      const eng = (await import(/* webpackChunkName: "translations/en" */ './en')).default;

      if (key === DEBUG_LANG_KEY && (is.local || is.stage || is.debug)) return getDebugTranslationFile(eng);

      return eng;
    }
  }
};

const loadMomentLocale = async (key) => {
  if (key === DEFAULT_LOCALE_KEY) return;

  switch (key) {
    case SUPPORTED_LANGS.uk: {
      return import(
        /* webpackMode: "eager" */
        'moment/locale/uk'
      );
    }
    case SUPPORTED_LANGS.id: {
      return import(
        /* webpackChunkName: "lang-id" */
        'moment/locale/id'
      );
    }
    default:
  }
};

export const setTranslationsToCssRoot = async (langTranslations) => {
  const translations = langTranslations;
  const cssTranslations = {};

  Object.keys(translations)
    .filter((key) => key.includes('--'))
    .forEach(
      (key) => (cssTranslations[
        key.replace('--', '')
      ] = `"${translations[key]}"`)
    );
  CssRoot.setRoot({ ...cssTranslations });
};

export const setUserLang = async (language = 'en') => {
  const langKey = numLangCode2strLang(language);

  const langTranslations = await getTranslationFile(langKey);

  await loadMomentLocale(langKey);

  i18next.addResourceBundle(
    langKey,
    'translations',
    langTranslations,
    true,
    true
  );
  i18next.changeLanguage(langKey);
  moment.locale(langKey);
  emitter.emit('i18nLanguageChanged', {
    language: langKey,
    translations: langTranslations,
  });

  setTranslationsToCssRoot(langTranslations)
    .catch((error) => error); // error appears because cssRoot has been set before render
};

export default i18next;
