/**
 * Wraps a fetch request with an abort controller
 * @param {Function} fn
 * @param {Object} args
 * @returns {Promise<unknown>}
 */
const withCancelRequest = (fn, ...args) => {
  let xhr;

  const promise = new Promise((resolve, reject) => {
    fn(...args, {
      getXHR: (instance) => {
        xhr = instance;
      },
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

  promise.cancelRequest = () => {
    xhr.abort();
  };

  return promise;
};

export default withCancelRequest;
