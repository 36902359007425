/**
 * @param {{}} color
 * @return {string}
 * Converts a Color instance into preColor string
 */
const toPreColor = (color) => color
  .array()
  .map(Math.ceil)
  .join(', ');

export default toPreColor;
