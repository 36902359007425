/**
 * Read the body of the response
 * @param {XMLHttpRequest} xhr
 * @returns {string | Document}
 */
export const readBody = (xhr) => {
  let data;

  if (!xhr.responseType || xhr.responseType === 'text') {
    data = xhr.responseText;
  } else if (xhr.responseType === 'document') {
    data = xhr.responseXML;
  } else {
    data = xhr.response;
  }

  return data;
};

/**
 * Create XHR request
 * @param {XMLHttpRequest=} propsXhr
 * @param {String} url
 * @param {String} method
 * @param {Object} headers
 * @param {FormData|File|Object=} data
 * @param {Function=} onUploadProgress
 * @returns {Promise<unknown>}
 */
const createXHR = ({
  xhr: propsXhr,
  url,
  method,
  headers,
  data,
  onUploadProgress,
}) => {
  const xhr = propsXhr || new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    xhr.open(method, url, true);
    xhr.overrideMimeType('application/json; charset=utf-8');

    Object.entries(headers).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });

    xhr.onload = () => {
      resolve(xhr);
    };

    xhr.onerror = () => {
      reject(xhr);
    };

    if (onUploadProgress) {
      xhr.upload.onprogress = onUploadProgress;
    }

    xhr.send(data || null);
  });
};

export default createXHR;
