export const CSS_VAR_KEY_REGEX = /--\w+/;
export const CSS_VAR_REGEX = new RegExp('var\\s*\\(\\s*(--[^\\)]*)\\s*\\)', 'i');

export const PRECOLOR_PATTERN = '(\\d{1,3}),\\s*(\\d{1,3}),\\s*(\\d{1,3})';
export const PRECOLOR_REGEX = new RegExp(PRECOLOR_PATTERN);
export const PRECOLOR_REGEX_STRICT = new RegExp(`^${PRECOLOR_PATTERN}$`);

export const ACCECTABLE_CONRAST_RATIO = 4.5;
export const DEPRECATED_NAMES = ['length', 'function'];
export const DEFAULT_COLOR = 'rgba(0,0,0,0)';

export default {
  CSS_VAR_REGEX,
  CSS_VAR_KEY_REGEX,
  DEFAULT_COLOR,
  DEPRECATED_NAMES,
  PRECOLOR_REGEX,
  PRECOLOR_REGEX_STRICT,
};
