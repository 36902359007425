import isString from '@sp/core/helper/lodash/isString';

import { CSS_VAR_REGEX } from '../constants';

/**
 * @param {string} value
 * @return {string}
 */
const extractVar = (value) => {
  if (!isString(value)) return value;

  const match = value.match(CSS_VAR_REGEX);

  if (match && match[1]) return match[1];

  return value;
};

export default extractVar;
