const isEqualTypes = (a, b) => typeof a === typeof b;
const isNotObject = (a) => typeof a !== 'object' || !a;

const isEqual = (a, b) => {
  if (a === b) return true;
  if (!isEqualTypes(a, b)) return false;
  if (isNotObject(a) || isNotObject(b)) return a === b;

  let equal = true;
  const stack = [[a, b]];

  while (stack.length > 0) {
    const [objA, objB] = stack.pop();

    if (objA === objB) continue;

    if (!isEqualTypes(objA, objB)) {
      equal = false;

      break;
    }
    if (isNotObject(objA) || isNotObject(objB)) {
      equal = objA === objB;

      if (!equal) break;
    }

    if (Array.isArray(objA)) {
      if (objA.length !== objB.length) {
        equal = false;

        break;
      }

      for (let i = 0; i < objA.length; i += 1) {
        if (objA[i] === objB[i]) continue;

        if (!isEqualTypes(objA[i], objB[i])) {
          equal = false;

          break;
        }

        if (isNotObject(objA[i]) || isNotObject(objB[i])) {
          equal = objA[i] === objB[i];

          if (!equal) break;

          continue;
        }

        stack.push([objA[i], objB[i]]);
      }

      if (!equal) break;

      continue;
    }

    if (objA.constructor === Object && objB.constructor === Object) {
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) {
        equal = false;

        break;
      }

      for (let i = 0; i < keysA.length; i += 1) {
        const key = keysA[i];

        if (objA[key] === objB[key]) continue;

        if (!isEqualTypes(objA[key], objB[key])) {
          equal = false;

          break;
        }

        if (isNotObject(objA[key]) || isNotObject(objB[key])) {
          equal = objA[key] === objB[key];

          if (!equal) break;

          continue;
        }

        stack.push([objA[key], objB[key]]);
      }

      if (!equal) break;

      continue;
    }

    equal = objA === objB;
  }

  return equal;
};

export default isEqual;
