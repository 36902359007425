export const defaultRootStyles = {
  defaultColor: '#ff3377',
  hoverColor: '#ed004f',
  focusColor: '#ed004f',
  pressedColor: '#c20041',
  borderRadius: '4px',
};

// Color from spec 'Custom manager (CRMS)' -> 'Settings' -> 'Branding' -> '2.1. General' -> 'Color themes' -> 'Active'
export const activeRootStyles = {
  defaultColor: '#ff3377',
  hoverColor: '#e52e6b',
  focusColor: '#e52e6b',
  pressedColor: '#CC295F',
  borderRadius: '3px',
  browserColorTheme: '#ff3377',
};

// Color from spec 'Custom manager (CRMS)' -> 'Settings' -> 'Branding' -> '2.1. General' -> 'Color themes' -> 'Neutral'
export const neutralRootStyles = {
  defaultColor: '#33aaff',
  hoverColor: '#2e99e5',
  focusColor: '#2e99e5',
  pressedColor: '#2988cc',
  borderRadius: '3px',
  browserColorTheme: '#33aaff',
};

export const colorThemeTypes = {
  ACTIVE: 'active',
  NEUTRAL: 'neutral',
  CUSTOM: 'custom',
};
