import compose from '../compose';

import toPreColor from './toPreColor';
import toValidColor from './toValidColor';

/**
 * Get darkened preColor
 * @param {string} value A color string
 * @param {number} magnitude Amount of darkness applied
 * @param {Object} collection Css root variables source
 */
const darken = (value, magnitude, collection) => {
  const args = [
    toPreColor,
    (c) => c.darken(magnitude / 100).rgb(),
    (color) => toValidColor(color, collection),
  ];

  return compose(...args)(value);
};

export default darken;
