import CssRoot from '@sp/css-root';

import {
  activeRootStyles,
  colorThemeTypes,
  defaultRootStyles,
  neutralRootStyles,
} from '../../constants';

/**
 * Set branding theme styles to the :root
 * @param {Object} brandingTheme
 */
const setBrandingThemeStyles = (brandingTheme) => {
  const { colorTheme } = brandingTheme || {};
  let preparedStyles;

  switch (colorTheme) {
    case colorThemeTypes.ACTIVE: {
      preparedStyles = activeRootStyles;

      break;
    }
    case colorThemeTypes.NEUTRAL: {
      preparedStyles = neutralRootStyles;

      break;
    }
    default: {
      preparedStyles = brandingTheme;
    }
  }

  CssRoot.setRoot({
    brandingTheme_defaultColor: preparedStyles?.defaultColor || defaultRootStyles.defaultColor,
    brandingTheme_hoverColor: preparedStyles?.hoverColor || defaultRootStyles.hoverColor,
    brandingTheme_focusColor: preparedStyles?.focusColor || defaultRootStyles.focusColor,
    brandingTheme_pressedColor: preparedStyles?.pressedColor || defaultRootStyles.pressedColor,
    brandingTheme_borderRadius: preparedStyles?.borderRadius || defaultRootStyles.borderRadius,
  });
};

export default setBrandingThemeStyles;
