import darken from './color/darken';
import lighten from './color/lighten';
import lightness from './color/lightness';
import getPrefixedName from './getPrefixedName';

/**
 * Handles function variable types by returning
 * a base css-root object after applying color function
 * to passed action
 */
const fnHandle = (action, collection) => {
  const key = getPrefixedName(action);

  switch (action.type) {
    case 'darken': {
      return {
        [key]: darken(action.cssVarValue, action.value, collection),
      };
    }
    case 'lighten': {
      return {
        [key]: lighten(action.cssVarValue, action.value, collection),
      };
    }
    case 'lightness': {
      return {
        [key]: lightness(
          action.cssVarValue,
          action.darkColor,
          action.lightColor,
          collection
        ),
      };
    }
    case 'rgb': {
      return {
        [`${action.name}`]: `rgb(var(${action.value}))`,
      };
    }
    default: {
      break;
    }
  }
};

export default fnHandle;
