/**
 * Adds query params to existing URLs
 * @param {string} url
 * @param {object} params
 * @returns {string}
 */
const addQueryParamsToUrl = (url, params = {}) => {
  if (!url || !params) return url;

  let fakeBase;
  let modifiedUrl;

  try {
    // if URL is relative, we'll need to add a fake base
    fakeBase = url.startsWith('http') ? undefined : 'http://fake-base.com/';
    modifiedUrl = new URL(url || '', fakeBase);
  } catch (error) {
    console.error(error);
  }

  if (!modifiedUrl) return url;

  Object.keys(params).forEach((key) => {
    if (modifiedUrl.searchParams.has(key)) {
      modifiedUrl.searchParams.set(key, params[key]);
    } else {
      modifiedUrl.searchParams.append(key, params[key]);
    }
  });

  // return as string (remove fake base if present)
  return modifiedUrl.toString().replace(fakeBase, '');
};

export default addQueryParamsToUrl;
