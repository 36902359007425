import React from 'react';

import Authorization from './Authorization';

const Landing = (props) => {
  const { reseller } = props;

  return <Authorization {...props} reseller={reseller} />;
};

export default Landing;
