import i18next from 'i18next';

import memoize from '@sp/core/helper/lodash/memoize';

import { messages } from './messages';

export const composeValidators = (...validators) => (...args) => validators.reduce(
  (error, validator) => error || validator(...args),
  null
);

export const getValidationMessage = (params) => {
  const {
    error,
    field,
  } = params;
  const errorType = error.type || error;
  let conf = messages[errorType || field] || messages.DEFAULT;

  if (!(conf instanceof Function) && typeof conf !== 'string') {
    conf = conf[field] || conf.DEFAULT;
  }
  if (conf instanceof Function) {
    return conf(params);
  }
  if (typeof conf === 'string') return i18next.t(conf);

  throw new Error(
    `Validation message for field ${field} with error ${errorType} was not found`
  );
};

export const getUserLocation = memoize(async () => {
  const geoIpService = window.API_URLS?.geoipService;

  if (!geoIpService) return;

  const geoDataResponse = await fetch(`${geoIpService}/geoip`);

  if (!geoDataResponse?.ok) return;

  return geoDataResponse.json();
});
