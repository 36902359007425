import isDate from '@sp/core/helper/lodash/isDate';
import moment from 'moment';

/**
 * Date formatter based on Moment.js format
 *
 * @param {Object|String} date String or a Moment object to be converted
 * @param {String} format String represents the resulting date format
 */
export const formatDate = (date, format) => {
  const momentObj = moment.isMoment(date) ? date : moment(date);

  return momentObj.format(format);
};

/**
 * Difference between two dates in minutes
 * @param dateObjFrom
 * @param dateObjTo
 * @returns {null|number}
 */

export const dateDiffInMinutes = (dateObjFrom, dateObjTo) => {
  if (!isDate(dateObjFrom) || !isDate(dateObjTo)) return null;

  return Math.abs(dateObjFrom.getTime() - dateObjTo.getTime()) / (1000 * 60);
};

//  Date-time options (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat)

// weekday: 'long', 'short', 'narrow'
// era: 'long', 'short', 'narrow'
// year: 'numeric', '2-digit'
// month: 'numeric', '2-digit', 'long', 'short', 'narrow'
// day: 'numeric', '2-digit'
// dayPeriod:  'in the morning', 'am', 'noon', 'n', 'narrow', 'short', 'long'
// hour: 'numeric', '2-digit'
// minute: 'numeric', '2-digit'
// second: 'numeric', '2-digit'
// timeZoneName: 'long', 'short', 'shortOffset', 'longOffset', 'shortGeneric', 'longGeneric'

export const getFormatDate = (date, options, locale = 'en-GB') => new Date(date).toLocaleDateString(locale, options);
