import isObject from '@sp/core/helper/lodash/isObject';

/**
 * Transforms raw inputs into CSSRootSource
 */
const transformRoot = (source) => {
  console.warn(
    Array.isArray(source) || isObject(source),
    '%s is not a valid source type. Provide an object or array instead',
    typeof source
  );

  return Array.isArray(source)
    ? source
      .filter((key, index) => index === 0 || index % 2 === 0)
      .reduce((
        state,
        prop,
        index
      ) => ({
        ...state,
        [prop]: source[index * 2 + 1],
      }), {})
    : source;
};

export default transformRoot;
