import omit from '@sp/core/helper/lodash/omit';
import { logout } from '@sp/core/helper/redirect';
import addQueryParamsToUrl from '@sp/core/helper/urls/addQueryParamsToUrl';

import createXHR from './createXHR';
import getHandlers from './getHandlers';
import getHeaders from './getHeaders';
import getQueryParams from './getQueryParams';
import prepareBodyRequest from './prepareBodyRequest';
import prepareResponse from './prepareResponse';

/**
 * Create request
 * @param {String} url
 * @param {String} method
 * @param {Object=} data
 * @param {Object=} config
 * @param {function=} getXHR
 * @returns {Promise<{}>}
 */
const createRequest = async ({
  url,
  method,
  data,
  config,
}, { getXHR } = {}) => {
  let timeoutId;

  const {
    headers,
    headersOptions,
    retryable = true,
    timeout,
    onUploadProgress,
    ...restParams
  } = config || {};

  const xhr = new XMLHttpRequest();
  const clearRestParams = omit(restParams, ['retries', 'retryDelay']);
  const queryParams = getQueryParams(method, clearRestParams);

  if (getXHR) getXHR(xhr);
  if (timeout) {
    timeoutId = setTimeout(() => xhr.abort(), timeout);
  }

  const preparedHeaders = getHeaders(method, headers, {
    ...clearRestParams,
    ...headersOptions,
  });

  const { handleErrorStatus, handleNetworkError } = getHandlers({
    url,
    method,
    data,
    config,
    timeoutId,
    retryable,
    createRequest,
  });

  try {
    const request = await createXHR({
      xhr,
      url: addQueryParamsToUrl(url, queryParams),
      method,
      headers: preparedHeaders,
      ...data && { data: prepareBodyRequest(data) },
      onUploadProgress,
    });

    const response = await prepareResponse(request, config);
    const isErrorStatus = response.status >= 400;

    if (!isErrorStatus) return response;
    if (response.status === 403 && response.data?.type === 'authentication') return logout();

    return handleErrorStatus({ request, response });
  } catch (error) {
    return handleNetworkError(error);
  } finally {
    if (timeoutId) clearTimeout(timeoutId);
  }
};

export default createRequest;
