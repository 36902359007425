import { useRef } from 'react';

const useEvent = (func) => {
  const funcRef = useRef(func);

  const staticFuncRef = useRef((...args) => funcRef.current(...args));

  funcRef.current = func;

  return staticFuncRef.current;
};

export default useEvent;
