import domScrollTop from 'dom-helpers/scrollTop';

import { easeInOutCubic } from '@sp/core/helper/dom/scrollTo';
import useEvent from '@sp/core/helper/hooks/useEvent';
import isFunction from '@sp/core/helper/lodash/isFunction';

import applyHideHelpBoxClass from './fn/applyHideHelpBoxClass';

const requestAnimFrame = (() => (
  window.requestAnimationFrame
  || window.webkitRequestAnimationFrame
  || window.mozRequestAnimationFrame
  || window.oRequestAnimationFrame
  || window.msRequestAnimationFrame
  || ((callback) => {
    window.setTimeout(callback, 1000 / 60);
  })
))();

const cancelAnimFrame = (() => (
  window.cancelAnimationFrame
  || window.webkitCancelRequestAnimationFrame
  || window.mozCancelRequestAnimationFrame
  || window.oCancelRequestAnimationFrame
  || window.msCancelRequestAnimationFrame
  || clearTimeout
).bind(window))();

const animateScroll = (top, options = {}, container) => {
  if (!container) return;

  const isScrollbar = !!container.getScrollTop;
  const start = isScrollbar
    ? container.getScrollTop()
    : domScrollTop(container);
  const opt = {
    duration: options.duration || 400,
    offset: options.offset || 0,
    callback: options.callback,
    onCancel: options.onCancel,
    easing: options.easing || easeInOutCubic,
    stopOnUserScroll:
      options.stopOnUserScroll === undefined
        ? true
        : options.stopOnUserScroll,
  };
  const distance = top - start;
  const duration = typeof opt.duration === 'function'
    ? opt.duration(distance)
    : opt.duration;
  let timeStart;
  let timeElapsed;
  let frameId;
  let initialFrameId;
  let cancelled;

  if (opt.stopOnUserScroll) {
    const el = isScrollbar ? container.view : container;

    const handleUserScroll = () => {
      el.removeEventListener('wheel', handleUserScroll);
      el.removeEventListener('touchmove', handleUserScroll);
      cancelAnimFrame(frameId);
      cancelAnimFrame(initialFrameId);
      cancelled = true;

      if (typeof opt.onCancel === 'function') opt.onCancel();
    };

    el?.addEventListener('wheel', handleUserScroll);
    el?.addEventListener('touchmove', handleUserScroll);
  }

  const scrollTop = (offset) => {
    if (isScrollbar) {
      return container.scrollTop(offset);
    }

    return domScrollTop(container, offset);
  };

  const end = () => {
    const offset = start + distance;

    scrollTop(offset);

    if (typeof opt.callback === 'function') opt.callback();
  };

  const loop = (time) => {
    if (cancelled) return;

    timeElapsed = time - timeStart;
    const offset = opt.easing(timeElapsed, start, distance, duration);

    scrollTop(offset);

    if (timeElapsed < duration) {
      frameId = requestAnimFrame(loop);

      return;
    }

    end();
  };

  initialFrameId = requestAnimFrame((time) => {
    timeStart = time;
    loop(time, container);
  });
};

const useHandlers = ({
  horizontal,
  onThumbDragging,
  scrollBarRef,
  onScrollStop,
  scrollArea,
}) => {
  const handleScrollStop = useEvent(() => {
    if (!onScrollStop) return;

    onScrollStop(scrollBarRef.current.getScrollTop());
  });
  const handleStopDraggingThumb = useEvent(() => {
    if (onThumbDragging) onThumbDragging(false);

    document.removeEventListener('mouseup', handleStopDraggingThumb);
  });
  const handleStartDraggingThumb = useEvent((e) => {
    const { target } = e;

    if (
      target !== scrollBarRef.current?.thumbVertical
      && target !== scrollBarRef.current?.thumbHorizontal
    ) {
      return;
    }

    if (onThumbDragging) onThumbDragging(true);

    document.addEventListener('mouseup', handleStopDraggingThumb);
  });
  const handleScroll = useEvent((e) => {
    let closestScroll = e.target;

    while (closestScroll.parentNode) {
      if (
        closestScroll.classList.contains('scrollbar__box')
        || closestScroll.classList.contains('scrollbar2__box')
      ) {
        break;
      }

      closestScroll = closestScroll.parentNode;
    }

    const scrollParent = scrollArea.current?.parentNode || {};
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
    } = scrollParent;
    const delta = e.deltaY || e.detail || e.wheelDelta;
    const dir = delta > 0 ? -1 : 1;

    if (
      !horizontal && (
        (dir === 1 && scrollTop === 0)
        || (dir === -1 && scrollTop === scrollHeight - clientHeight)
      )
      && closestScroll === scrollParent
    ) {
      e.preventDefault();
    }
  });
  const scrollAnimate = useEvent((top, options = {}) => {
    animateScroll(top, options, scrollBarRef.current);
  });
  const getScrollTop = useEvent(() => {
    if (isFunction(scrollBarRef.current.getValues())) {
      applyHideHelpBoxClass(scrollBarRef.current.getValues());
    }

    return scrollBarRef.current.getScrollTop();
  });

  return {
    handleStopDraggingThumb,
    handleStartDraggingThumb,
    handleScroll,
    scrollAnimate,
    getScrollTop,
    handleScrollStop,
  };
};

export {
  animateScroll,
};

export default useHandlers;
