import { IMAGE_API_SERVICE } from '@sp/core/api/api_urls';
import { getUserData } from '@sp/core/helper';
import { applyBundleUrlOverload, getSVG } from '@sp/core/helper/image';

const host = `${IMAGE_API_SERVICE}/projects`;
const GLOBAL = 'global';
const TEMPLATES = 'templates';
const DICTIONARY = {
  landing: [GLOBAL, 'comingsoon'],
  dashboard: [GLOBAL, 'dashboard'],
  editor: [GLOBAL, 'redactor', 'toolbar', TEMPLATES],
};
const ICONS_IDS = {
  custom: 'icons-custom',
};

export const loadIconsPack = (env) => {
  const sprite = DICTIONARY[env];

  sprite.map((name) => {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const path = require(`@sp/icon/SpriteIcons/${name}.svg`);

    getSVG(`${applyBundleUrlOverload(path.default)}`);
  });
};

export const loadSvgLoaderIcons = () => {
  const assetsPath = applyBundleUrlOverload('/assets');

  // Init icons by svg-sprite-loader
  if (Array.isArray(window.sprites)) {
    window.sprites.forEach((link) => !['help', 'editor', 'dashboard'].some((name) => link.includes(name)) && getSVG(`${assetsPath}/${link}`));
  }
};

const svgPreload = (data) => {
  // SP-37787 fix broken icon
  // eslint-disable-next-line unicorn/prefer-query-selector
  const symbol = data.getElementById('icons:41849');
  const rect = symbol && symbol.querySelectorAll('rect')[0];

  if (rect) {
    rect.remove();
  }

  return data;
};

export const loadCustomSprite = () => {
  const { projectId } = getUserData();

  if (!projectId) return;

  if (window.STATIC && window.STATIC.status && window.STATIC.dirName) {
    return getSVG(`${window.STATIC.dirName}/icons.svg?timestamp=${Date.now()}`, null, {
      id: ICONS_IDS.custom,
    });
  }

  getSVG(`${host}/${projectId}/icons.svg?timestamp=${Date.now()}`, svgPreload, {
    id: ICONS_IDS.custom,
  });
};

export default (env) => {
  loadSvgLoaderIcons();
  loadIconsPack(env);
  loadCustomSprite();
};
