import React from 'react';
import PropTypes from 'prop-types';

import { getQueryParams } from '@sp/core/helper/urls/link';
import IconClose18 from '@sp/icon/Close18';

import { setBrandingThemeStyles } from '../../utils';

const EmailConfirm = ({ reseller = {} }) => {
  const {
    brandFavicon,
    resellerName,
    brandingTheme,
    loginUrl,
    brandUrl,
  } = reseller;
  const location = window.location.href;
  const queryParams = getQueryParams(location);
  const isExpiredToken = queryParams?.status === 'expired';

  setBrandingThemeStyles(brandingTheme);

  return (
    <div className="auth__wrap _confirm-page">
      <a
        className="auth__btn_close"
        href={brandUrl || '/'}
      >
        <IconClose18 />
      </a>
      <div className="auth__email-confirmation">
        <div className="auth__logo">
          {brandFavicon && (
            <img
              src={brandFavicon}
              alt={`${resellerName || 'Brand'} logo`}
            />
          )}
        </div>
        {isExpiredToken
          ? (
            <div className="auth__text">
              <h1 className="auth__title">Email verification link expired</h1>
              <h5 className="auth__subtitle">The link you followed has expired.</h5>
              <a
                className="btn2 btn2__grey auth__btn"
                href={brandUrl || '/'}
              >
                Got It
              </a>
            </div>
          )
          : (
            <div className="auth__text">
              <h1 className="auth__title">Email Confirmed</h1>
              <h5 className="auth__subtitle">Your email address has been successfully updated, and you can now use it to log into your account.</h5>
              <a
                className="btn2 auth__btn"
                href={loginUrl || '/'}
              >
                Log In
              </a>
            </div>
          )}
      </div>
    </div>
  );
};

EmailConfirm.propTypes = {
  reseller: PropTypes.shape({
    brandFavicon: PropTypes.string,
    resellerName: PropTypes.string,
    brandingTheme: PropTypes.object,
    loginUrl: PropTypes.string,
    brandUrl: PropTypes.string,
  }),
};

export default EmailConfirm;
