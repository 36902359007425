import toRGB from './color/toRGB';
import fnHandle from './fnHandle';
import recursiveVarFind from './recursiveVarFind';
import toCssRootKeys from './toCssRootKeys';

/**
 * Get function variables from cssVars, predefined in collection.
 *
 * @param {Object} cssVars Object represents css variables set to root.
 * Used to get primary colors for each function described in collection.
 *
 * @param {Object} collection
 *
 * @returns {Object} Set of function variables.
 */
const getRootFunctions = (cssVars, collection) => collection.reduce((state, variable) => {
  const cssVarValue = toRGB(
    recursiveVarFind(`--${variable.name}`, cssVars)
  );

  return {
    ...state,
    ...toCssRootKeys(fnHandle({ cssVarValue, ...variable }, cssVars)),
  };
}, {});

export default getRootFunctions;
