import React from 'react';
import cx from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import isFunction from '@sp/core/helper/lodash/isFunction';
import isString from '@sp/core/helper/lodash/isString';

import InputHeader from './components/InputHeader';

import './index.scss';

const Input = (props) => {
  const {
    multiple,
    children,
    className,
    placeholder,
    label,
    labelRender,
    error,
    errorRender,
    innerRef,
    outerRef,
    preventShowError,
    withScroll,
    withHeader = true,
    inputClass = 'input2',
    title,
    iconPrefix,
    isDateInput,
    description,
    passwordRef,
    ...restProps
  } = props;

  const renderLabel = () => {
    if (!label) return '';
    if (isFunction(labelRender)) return labelRender(props);

    return (
      <label className="label">
        {i18next.exists(label)
          ? i18next.t(label)
          : label}
      </label>
    );
  };

  const renderError = () => {
    if (preventShowError || !error) return '';
    if (isFunction(errorRender)) return errorRender(error);

    return (
      <span className="input-error-text input-error-text_visible">
        {isString(error) && i18next.exists(error) ? i18next.t(error) : error}
      </span>
    );
  };

  const classNames = cx(className, 'input-wrap', {
    'input-wrap_error': Boolean(error) && !preventShowError,
    _prefixed: !!iconPrefix,
  });

  return (
    <div className={classNames} ref={outerRef}>
      {renderLabel()}
      <div className={`input__icon-wrap ${restProps.type === 'password' ? 'password-field' : ''}`} ref={passwordRef}>
        {withHeader && (
          <InputHeader
            withScroll={withScroll}
            placeholder={placeholder || null}
            multiple={multiple}
            innerRef={innerRef}
            restProps={restProps}
            inputClass={inputClass}
            id={title || null}
          />
        )}
        {children}
        {iconPrefix && (
          <label className="input__prefix" htmlFor={title}>
            {iconPrefix}
          </label>
        )}
        {isDateInput && (<div className="input__icon-holder" />)}
      </div>
      {renderError()}
      {description && (
        <div className="input-description">
          {description}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  spellCheck: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  preventShowError: PropTypes.bool,
  inputClass: PropTypes.string,
  title: PropTypes.string,
  dynamic: PropTypes.object,
  multiple: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelRender: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
  errorRender: PropTypes.func,
  innerRef: PropTypes.any,
  outerRef: PropTypes.any,
  passwordRef: PropTypes.object,
  withScroll: PropTypes.bool,
  withHeader: PropTypes.bool,
  iconPrefix: PropTypes.string,
  isDateInput: PropTypes.bool,
  description: PropTypes.string,
};

export default Input;
