import React, {
  useCallback, useEffect, useReducer, useRef,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import is from '@sp/core/helper/browser/is';
import useEvent from '@sp/core/helper/hooks/useEvent';
import useIsMount from '@sp/core/helper/hooks/useIsMount';
import isEqual from '@sp/core/helper/lodash/isEqual';
import isFunction from '@sp/core/helper/lodash/isFunction';
import omit from '@sp/core/helper/lodash/omit';

import useHandlers, { animateScroll } from './useHandlers';

import './index.scss';

let activeScrollbars;

const isDragging = () => activeScrollbars && activeScrollbars.dragging;

const resetDragging = () => activeScrollbars = null;
const defaultClass = 'scrollbar2';

const Scroll = ({
  checkMobile,
  children,
  className,
  containerProps = {},
  dark,
  onScroll,
  onScrollStart,
  onScrollFrame,
  scrollbar,
  onScrollStop,
  scrollbarWidth = 0,
  horizontal,
  scrollTop = 0,
  onThumbDragging,
  customScrollTop,
  device,
  setRef,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isMount = useIsMount();
  const scrollArea = useRef(null);
  const scrollBarRef = useRef(null);
  const classes = classNames(
    className,
    defaultClass,
    { [`${defaultClass}_dark`]: dark }
  );

  const checkRef = useCallback((ref) => {
    if (isFunction(scrollbar)) scrollbar(ref);

    scrollBarRef.current = ref;
  }, [scrollbar]);

  const {
    handleStartDraggingThumb,
    handleScroll,
    scrollAnimate,
    getScrollTop,
    handleScrollStop,
  } = useHandlers({
    horizontal,
    onThumbDragging,
    scrollBarRef,
    onScrollStop,
    scrollArea,
  });

  const renderView = useEvent(({ style: { marginRight } }) => (
    <div
      className={classNames(`${defaultClass}__box scroll-overflow-container`, {
        [`${defaultClass}__box_horizontal`]: horizontal,
      })}
      style={{
        marginRight: horizontal ? 0 : (marginRight || scrollbarWidth),
      }}
      {...containerProps}
    />
  ));
  const renderTrackVertical = useEvent(({ style }) => {
    if (horizontal) return <div className="hidden" />;

    return (
      <div
        className={`${defaultClass}__track`}
        style={omit(style, 'width')}
      />
    );
  });
  const renderThumb = useEvent(({ style }) => (
    <div
      onMouseEnter={() => {
        activeScrollbars = scrollBarRef.current;
      }}
      className={`${defaultClass}__thumb`}
      style={style}
    />
  ));
  const renderTrackHorizontal = useEvent(({ style }) => {
    if (!horizontal) return <div className="hidden" />;

    return (
      <div
        className={`${defaultClass}__track ${defaultClass}__track_horizontal`}
        style={omit(style, 'height')}
      />
    );
  });

  const scrollTopEvent = useEvent((top) => {
    if (!isFunction(scrollBarRef.current?.scrollTop) || is.mobile()) return;

    scrollBarRef.current?.scrollTop(top);
  });
  const getDom = useEvent(() => scrollBarRef.current.container);
  const updateScroll = useEvent(() => !is.mobile() && scrollBarRef.current.update());
  const getScrollParent = useEvent(() => scrollArea.current.parentNode);

  useEffect(() => {
    if (!setRef) return;

    setRef({
      scrollTop: scrollTopEvent,
      scrollAnimate,
      getScrollTop,
      getDom,
      updateScroll,
      getScrollParent,
      forceUpdate,
    });
  }, [scrollBarRef.current]);

  useEffect(() => {
    if (!scrollArea.current) return;

    const { parentNode } = scrollArea.current;

    scrollTopEvent(scrollTop);

    if (onThumbDragging) {
      scrollBarRef.current.container.addEventListener(
        'mousedown',
        handleStartDraggingThumb,
        true
      );
    }

    if (!is.mobile() && !checkMobile) {
      parentNode.addEventListener('scroll', handleScroll);
      parentNode.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (onThumbDragging && scrollBarRef.current) {
        scrollBarRef.current.container.removeEventListener(
          'mousedown',
          handleStartDraggingThumb
        );
      }

      resetDragging();

      if (is.mobile() || checkMobile || !parentNode) return;

      parentNode.removeEventListener('scroll', handleScroll);
      parentNode.removeEventListener('wheel', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (isMount || !scrollBarRef.current) return;

    scrollBarRef.current.scrollTop(customScrollTop);
  }, [customScrollTop]);
  useEffect(() => {
    if (isMount || !horizontal || !scrollBarRef.current) return;

    updateScroll();
  }, [device]);

  if (is.mobile() && checkMobile) {
    return (
      <div className={classes} ref={checkRef}>
        <div
          className={`${defaultClass}__box scroll-overflow-container`}
          {...containerProps}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <Scrollbars
      renderView={renderView}
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumb}
      renderTrackHorizontal={renderTrackHorizontal}
      renderThumbHorizontal={renderThumb}
      universal
      className={classes}
      onScroll={onScroll}
      onScrollStart={onScrollStart}
      onScrollStop={handleScrollStop}
      onScrollFrame={onScrollFrame}
      ref={checkRef}
    >
      <div
        className="scrollbar2__inner"
        ref={scrollArea}
      >
        {children}
      </div>
    </Scrollbars>
  );
};

Scroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  horizontal: PropTypes.bool,
  theme: PropTypes.string,
  dark: PropTypes.bool,
  scrollbar: PropTypes.func,
  onScroll: PropTypes.func,
  scrollTop: PropTypes.number,
  className: PropTypes.string,
  onScrollStop: PropTypes.func,
  onScrollFrame: PropTypes.func,
  onScrollStart: PropTypes.func,
  onThumbDragging: PropTypes.func,
  checkMobile: PropTypes.bool,
  scrollbarWidth: PropTypes.number,
  containerProps: PropTypes.object,
  customScrollTop: PropTypes.number,
  device: PropTypes.string,
  setContainerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  setRef: PropTypes.func,
};

export {
  animateScroll,
  isDragging,
};

export default React.memo(Scroll, isEqual);
