import toValidColor from './toValidColor';

/**
 * @param {string | number[]} color
 * @param {string} dark
 * @param {string} light
 * @param {Object} collection
 * @returns {string}
 */
const lightness = (color, dark, light, collection) => (toValidColor(color, collection).isLight() ? dark : light);

export default lightness;
