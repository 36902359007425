import { getUserData } from '@sp/core/helper';
import is from '@sp/core/helper/env/is';
import { getOnlyDigits } from '@sp/core/validation';

import { isFullUrl } from './urlInfo';

const withAnchor = (link, anchor) => {
  const baseUrl = link || '';

  if (!anchor) return baseUrl;

  const slash = baseUrl && baseUrl.at(-1) !== '/' ? '/' : '';

  return `${baseUrl}${slash}#${anchor}`;
};

/**
 * Converts page hash to valid url link if this hash is existing
 * @param menuItems - list of menu items. Have to be an object: { name : [ menu_items ], name2 : [ menu_items ], ...
 *   }, or Array: [ menu_item, menu_item, ... ]. menu_items - { hash: ..., name: ..., tag: ..., children: [], ... }
 * @param hash - page hash
 * @param anchor - usually section hash
 * @returns {string} - if hash is not exist in menu_items - return hash. Else return 'tag' by hash
 */
const pageHashToLink = (menuItems, hash, anchor) => {
  let activeTag = null;
  const { pageId } = getUserData();

  const iterateMenu = (menu, path) => {
    if (Array.isArray(menu) && menu.length > 0) {
      menu.forEach((menuItem) => {
        if (menuItem.hash === hash) {
          activeTag = pageId === menuItem.hash
            ? ''
            : (menuItem.homepage
              ? '/'
              : `${path}${menuItem.tag}`);
        } else if (menuItem.children) {
          const isParentHome = menuItem.homepage
            && menuItem.children.some((item) => item.hash === hash);
          const parentPath = isParentHome
            ? `${path}`
            : `${path}${menuItem.tag}/`;

          iterateMenu(menuItem.children, parentPath);
        }
      });
    } else if (menu && typeof menu === 'object') {
      for (const k in menu) {
        iterateMenu(menu[k], path);
      }
    }
  };

  if (pageId === hash) {
    activeTag = '';
  } else {
    iterateMenu(menuItems, '/');
  }

  const link = !activeTag && typeof activeTag !== 'string' ? hash : activeTag;

  return withAnchor(link, anchor) || undefined;
};

/**
 * Get right link from all links
 * @param href {string}
 * @param anchor {string}
 * @param menu {{}}
 * @returns {string}
 */
const getHref = (href, anchor, menu) => {
  const allLinks = menu && [...(menu.list || []), ...(menu.unlinked || [])];

  return pageHashToLink(allLinks, href, anchor);
};

// toDo Also used in blog-frontend
export const getLink = (type, href, anchor, menu) => {
  let link = (href || '').trim();

  if (link) {
    switch (type?.toLowerCase()) {
      case 'anchor':
      case 'page': {
        return getHref(link, anchor, menu);
      }
      case 'email': {
        link = `mailto:${link}`;

        break;
      }
      case 'phone': {
        const purePhone = getOnlyDigits(link);

        link = `tel:${link.includes('+') ? '+' : ''}${purePhone}`;

        break;
      }
      case 'url': {
        if (!isFullUrl(link) && link !== '/') link = `http://${link}`;

        break;
      }
      default: {
        break;
      }
    }
  }

  return link || undefined;
};

/**
 * Handles application ports when user is redirected
 * on local environment
 * @param link
 * @return {string}
 */
export function withLocal(link) {
  const {
    protocol,
    hostname,
  } = window.location;

  return is.local
    ? `${protocol}//${hostname}:8100${link}`
    : link;
}

export const joinReducer = (url, entry, which) => url.concat(`${which ? '&' : '?'}${entry[0]}=${entry[1]}`);

export const joinParams = (params, baseUrl = '') => Object.entries(params)
  .reduce(joinReducer, baseUrl);

export const encodeReducer = (url, entry, which) => url.concat(
  `${which ? '&' : '?'}${entry[0]}=${encodeURIComponent(entry[1])}`
);

export const encodeParams = (params, baseUrl = '') => Object.entries(params)
  .reduce(encodeReducer, baseUrl);

function decodeReducer(params, param) {
  const [key, value] = param.split('=');

  params[key] = value ? decodeURIComponent(value.replaceAll('+', ' ')) : '';

  return params;
}

/**
 * Adds query params to url string
 * @param {String} url can contain params or not
 * @param {Object} params that need to be added
 */
export function addQueryParams(url, params = {}) {
  const baseUrl = getBaseUrl(url);
  const existingParams = getQueryParams(url);
  const composedParams = {
    ...existingParams,
    ...params,
  };

  return encodeParams(composedParams, baseUrl);
}

/**
 * Get url without query params
 * @param {String} url
 */
export function getBaseUrl(url) {
  if (!url) return url;

  const query = url.indexOf('?');

  return url.slice(0, ~query ? query : url.length);
}

/**
 * Get query params from url string
 * @param query Full url or just location.search
 * @return {Object} Represents query params
 */
export function getQueryParams(query = '') {
  const initialState = {};

  return query.includes('?')
    ? query
      .slice(query.indexOf('?') + 1, query.length)
      .split('&')
      .reduce(decodeReducer, initialState)
    : initialState;
}

export const extractNameFromFileLink = (link) => {
  if (link.length === 0) return '';

  return link.split('/').pop();
};

export const getLinkProps = (link, linkType) => {
  if (typeof linkType === 'string') {
    const linkTypeNormalized = linkType.toLowerCase();

    if (linkTypeNormalized === 'page' || linkTypeNormalized !== 'file') {
      return { href: link };
    }
  }

  const linkNormalized = !link || typeof link !== 'string' ? '' : link;

  return {
    href: linkNormalized,
    download: extractNameFromFileLink(linkNormalized),
  };
};
