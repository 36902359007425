import delay from '@sp/core/helper/delay';

import makeErrorResponse from './makeErrorResponse';

/**
 * Make retry request
 * @param {Object} error
 * @param {String} url
 * @param {String} method
 * @param {Object=} data
 * @param {Object=} config
 * @param {Function} createRequestFn
 * @returns {Promise<*>}
 */
const makeRetryRequest = async (error, {
  url,
  method,
  data,
  config,
}, createRequestFn) => {
  const {
    retries = 3,
    retryDelay = 3000,
  } = config || {};
  const triesLeft = retries - 1;

  if (triesLeft < 0) throw makeErrorResponse(error);

  await delay(retryDelay);

  return createRequestFn({
    url,
    method,
    data,
    config: {
      ...config,
      retries: triesLeft,
    },
  });
};

export default makeRetryRequest;
