import merge from '@sp/core/helper/lodash/merge';

import { createRequest, getAccessToken, withCancelRequest } from './utils';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
const FetchWrapper = function (host, options) {
  this.options = options;

  const withBaseUrl = (url) => {
    if (!host) return url;
    if (url.startsWith('http')) return url;

    const clearHost = host.replace(/\/$/, '');
    const clearUrl = url.replace(/^\//, '');

    return `${clearHost}/${clearUrl}`;
  };

  return {
    host,
    options,
    accessToken: getAccessToken(),
    updateOptions: (newOptions) => {
      this.options = merge({}, this.options, newOptions);
    },
    get: (url, params, config) => withCancelRequest(createRequest, {
      url: withBaseUrl(url),
      method: 'GET',
      config: merge({}, this.options, params, config),
    }),
    post: (url, data, config) => withCancelRequest(createRequest, {
      url: withBaseUrl(url),
      method: 'POST',
      data,
      config: merge({}, this.options, config),
    }),
    put: (url, data, config) => withCancelRequest(createRequest, {
      url: withBaseUrl(url),
      method: 'PUT',
      data,
      config: merge({}, this.options, config),
    }),
    delete: (url, config) => withCancelRequest(createRequest, {
      url: withBaseUrl(url),
      method: 'DELETE',
      config: merge({}, this.options, config),
    }),
  };
};

export default FetchWrapper;
