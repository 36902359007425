import isEmpty from '@sp/core/helper/lodash/isEmpty';
import isNil from '@sp/core/helper/lodash/isNil';

import {
  FALLBACK_MESSAGE,
  REQUIRED_ERRORS,
  RULES,
  VALIDATION_ERRORS,
  VALIDATION_LENGTH,
} from './constants';

export const composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value),
  // eslint-disable-next-line unicorn/no-useless-undefined
  undefined
);

const validateLength = (entries) => {
  const [key, value] = entries;

  if (isNil(value)) {
    return VALIDATION_ERRORS[`${key}Empty`];
  }

  if (value.length < VALIDATION_LENGTH[`${key}Min`]) {
    return VALIDATION_ERRORS[`${key}LessThan`];
  }

  if (value.length > VALIDATION_LENGTH[`${key}Max`]) {
    return VALIDATION_ERRORS[`${key}MoreThan`];
  }
};

export const isValidField = (entries) => {
  const [key, value] = entries;

  return value.match(RULES[key]) ? undefined : VALIDATION_ERRORS[key];
};
export const required = (entries) => {
  const [key, value] = entries;

  if (!isNil(validateLength(entries))) {
    return validateLength(entries);
  }

  return isEmpty(value)
    ? REQUIRED_ERRORS[key]
    : (isEmpty(value.trim())
      ? REQUIRED_ERRORS[key]
      : undefined);
};

const basicValidation = composeValidators(required, isValidField);

export const validateValues = (values) => {
  const errors = Object.entries(values)
    .map((entries) => {
      const error = basicValidation(entries);

      return isNil(error)
        ? {}
        : {
          [entries[0]]: error,
        };
    });

  return errors.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const handleSubmitErrors = (error) => {
  const type = error?.response?.data?.type || FALLBACK_MESSAGE;
  let messageObj;

  switch (type) {
    case 'authentication': {
      messageObj = { email: error?.response?.data?.message };

      break;
    }
    case 'validation': {
      const messages = error?.response?.data?.messages;

      messageObj = messages
        .map(({
          field,
          message,
        }) => ({ [field]: message }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

      break;
    }
    default: {
      messageObj = { email: FALLBACK_MESSAGE };

      break;
    }
  }

  return Promise.resolve(messageObj);
};
